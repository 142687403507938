import Vue from 'vue';
import Vuex from 'vuex';
import idiviaService from '../api/idiviaService';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    /* VARIÁVEIS GLOBAIS */
    products: [],
    product: [],
    product_id: {},
    orders: [],
    order: [],
    orderProducts: [],
    shippingCompany: [],
    orderPicking: [],
    expeditionNacex: [],
    tagNacex: '',
    allExpedition: [],
    expedition: [],
    login: '',
    dhlExpedition: [],
    countries: [],
    dhlLabel: '',
    dhlTracking: [],
    pautalCodes: [],
    loggedUser: [],
    register: '',
    users: [],
    totalProcessing: '',
    totalPicking: '',
    cttExpressoExpedition: [],
    cttExpressoLabel: '',
    cttExpressoCloseLabel: '',
    cttOrdersClose: '',
    cttDataClose: '',
    invoiceNumber: '',
    invoiceDate: '',
    message: '',
    cttShipment: '',
    cttLabel: '',
    cttTracking: '',
    fedexData: '',
    fedexLabel: '',
    fedexCurrency: '',
    ean: [],
    zpl: '',
    suppliersInvoice: [],
    suppliersInvoiceID: [],
    allBrands: [],
    allSuppliers: [],
    supplier: [],
    allCountries: [],
    simplifiedInvoiceId: '',
    purchasesTransactions: [],
    productExtraInfo: '',
    productsMissing: [],
    productsMissingLocked: [],
    productsMissingDate: [],
    productsTransactions: [],
    farmaOrders: [],
    farmaOrdersConf: [],
    idTransaction: '',
    ordersFilter: [],
    allCloseCTT: [],
    productsFilter: [],
    productsSkuEan: [],
    logs: [],
    dashboard: [],
    cttExpressoShipments: [],
    cttShipments: [],
    prodTransactions: [],
    prodExtraInfo: [],
    amostras: '',
    codigo_order: '',
    taxs: '',
    tracking: [],
    ordersNotDel: [],
    searchInvoiceSuppliers: [],
    validCtt: '',
    pickingByID: [],
    cttFile: [],
    purchaseProducts: [],
    //NEW ORDERS
    ordersPendent: [],
    ordersPendentCS: [],
    ordersPendentEdit: [],
    ordersProcessing: [],
    ordersPicking: [],
    ordersPrinting: [],
    ordersPacking: [],
    ordersReturned: [],
    ordersLosted: [],
    ordersValidateAddress: [],
    previousOrderInfo: '',
    shippingCalculated: [],
    shippingMessage: '',
    shippingVariables: [],
    priceVariables: [],
    priceIntervalos: [],
    allOrdersProcessing: [],
    salesReport: [],
    salesReportPicked: [],
    salesReportAnual: [],
    returnedProducts: [],
    newID: '',
    countingDays: [],
    daysEvaluation: [],
    email: [],
    reportsValidity: [],
    validationAddress: [],
    reasonsDelay: [],
    //Discounts
    discounts: [],
    discountProducts: [],
    stockStatusVar: [],
    stockUpdateVar: [],
    allFeedValues: [],
    allFeedSettings: [],
    intervalFeed: [],
    margemByFeed: [],
    missingBrands: [],
    manualPrices: [],
    shippingFeed: [],
    margensFeed: [],
    aerosolReport: [],
    requestClient: [],
    allRequestsClient: [],
    filterClientRequests: [],
    lastClientRequest: [],
    productClient: [],
    allProductClient: [],
    searchClientAddress: [],
    countRequestEmail: [],
    clientDiscount: [],
    clientOrderShipping: [],
    //Clients
    searchClients: [],
    clientInfo: [],
    noProductFeed: [],
    //Translated
    allTranslated: [],
    productsClientPrice: [],
    listProductsClientPrice: [],
    //DANIELA PLATFORM - BUY TO BRANDS
    allBrandsPurchase: [],
    allDanielaPurchase: [],
    //LABORATORIES
    allLabs: [],
    farmasPurchaseLab: [],
    //Insurance
    allInsurances: [],
    //IMPORTS
    errorSKUS: [],
    errorUpdate: [],
    errorLimitDate: [],
    allImportFiles: [],
    importFile: '',
    //Categories
    allCategories: [],
    //Products
    product_categories: [],
    //Feeds
    allFeeds: [],
    //Coins
    allCoins: [],
    wasInternReturn: false,
    //Custom Labels
    customLabels: [],
    id_invoice: '',
    cttTrackinsOrders: [],
    //Carina
    carinaEmails: [],
    deliveriesNotesCtt: [],
    //Accounting
    suppliersWithDifference: [],
    accountingSuppliers: [],
    allReceptionsAccounting: [],
    receptionsToAccounting: [],
    accountingDetails: [],
  },
  mutations: {
    /* ALTERAÇÃO DAS VARIAVEIS GLOBAIS */
    /* PRODUTOS */
    SET_CTT_FILE(state, data) {
      state.cttFile = data.b64file;
    },
    SET_PRODUCTS(state, data) {
      state.products = data;
    },
    SET_PRODUCTS_ID(state, data) {
      state.product_id = data;
    },
    SET_PRODUCTS_EAN(state, data) {
      state.ean = data.data;
      console.log('EAN INDEX: ', data.data);
    },
    SET_AMOSTRAS(state, data) {
      state.amostras = data.data;
    },
    SET_CODIGO_ORDER(state, data) {
      state.codigo_order = data.data;
    },
    SET_TAXS(state, data) {
      state.taxs = data.data;
    },
    SET_PRODUCTS_SKU(state, data) {
      state.product = data.data;
    },
    SET_PRODUCTS_FILTER(state, data) {
      console.log(data.data);
      state.productsFilter = data.data;
    },
    SET_PRODUCTS_EAN_SKU(state, data) {
      state.productsSkuEan = data.data;
    },
    EDIT_PRODUCT() {},
    FIX_STOCK() {},
    /* ORDERS */
    SET_ORDERS(state, data) {
      state.orders = data;
    },
    SET_PREVIOUS_ORDER_INFO(state, data) {
      state.previousOrderInfo = data.data;
    },
    SET_ORDERS_FILTER(state, data) {
      state.ordersFilter = data.data;
    },
    SET_ORDERS_PROCESSING(state, data) {
      state.totalProcessing = data.data;
      console.log(data.data);
    },
    SET_ORDER_BY_ID(state, data) {
      state.order = data;
    },
    SET_ORDER_PRODUCTS(state, data) {
      state.orderProducts = data;
      console.log(state.orderProducts);
    },
    ADD_ORDER(state, data) {
      state.newID = data.newId;
    },
    UPDATE_ORDER_STATUS() {},
    /* MANUAL ORDER */
    ADD_MANUAL_ORDER(state, data) {
      state.message = data.message;
      console.log(data);
    },
    ADD_ORDER_PRODUCTS() {},
    EDIT_DATA_PRODUCTS() {},
    EDIT_ORDER_PRODUCTS() {},
    EDIT_ORDER_ARTICLES() {},
    ADD_ORDER_PROCESSING(sate, data) {
      console.log(data);
    },
    SET_ORDERS_PICKING(state, data) {
      state.orderPicking = data;
      state.totalPicking = data.data.length;
    },
    UPDATE_ADDRESS(state, data) {
      console.log(data);
    },
    UPDATE_CONTACT(state, data) {
      console.log(data);
    },
    UPDATE_STATUS(state, data) {
      console.log(data);
    },
    UPDATE_RETURN(state, data) {
      console.log(data);
    },
    /* SHIPPING COMPANIES */
    SET_SHIPPING_COMPANY(state, data) {
      state.shippingCompany = data;
    },

    /* USERS */
    LOGIN(state, data) {
      console.log(data);
      state.login = data.status;
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.data));
      state.loggedUser = data.data.username;
      console.log(state.loggedUser);
    },
    REGISTER(state, data) {
      state.register = data.message;
    },
    SET_ALL_USERS(state, data) {
      state.users = data.data;
    },
    UPDATE_ADIM_TYPE() {},
    UPDATE_BLOCKED() {},
    UPDATE_PASSWORD() {},
    SET_COUNTRIES(state, data) {
      state.countries = data;
    },
    SET_ALL_COUNTRIES(state, data) {
      state.allCountries = data.data;
    },
    SET_LOGS(state, data) {
      state.logs = data.data;
    },
    SET_DASHBOARD(state, data) {
      state.dashboard = data.data;
    },

    /* NACEX */
    SET_EXPEDITION_NACEX(state, data) {
      console.log('NACEX: ' + data.data.body);
    },
    CREATE_EXPEDITION(state, data) {
      state.expeditionNacex = data.data.body;
    },
    SET_TAG_NACEX(state, data) {
      state.tagNacex = data;
    },
    SET_ORDER_EXPEDITION(state, data) {
      state.allExpedition = data;
    },
    SET_ORDER_EXPEDITION_BY_ID(state, data) {
      state.expedition = data;
    },
    DELETE_EXPEDITION_NACEX() {},

    /* DHL */
    SET_SHIPMENTS(state, data) {
      console.log(data);
      state.dhlExpedition = data;
    },
    SET_SHIPMENTS_EXTRACOMUNITARY(state, data) {
      console.log(data);
      state.dhlExpedition = data;
    },
    SET_LABEL_DHL(state, data) {
      state.dhlLabel = data;
    },
    SET_TRACKING_DHL(state, data) {
      state.dhlTracking = data;
    },

    SET_DHL_CODES(state, data) {
      state.pautalCodes = data;
    },
    EDIT_XML(state, data) {
      console.log(data);
    },
    SEND_XML(state, data) {
      console.log(data);
    },
    /* MOLONI */
    CREATE_SIMPLIFIED_INVOICE(state, data) {
      state.simplifiedInvoiceId = data.data;
      console.log(data.data);
    },
    SET_PDF_LINK(state, data) {
      console.log(data.data);
      window.open(data.data, '_blank');
    },
    SET_PDF_FILE(state, data) {
      console.log(data.data);
      window.open(data.data, '_blank');
    },
    SET_INVOICE(state, data) {
      state.invoiceNumber = `${data.data.document_set_name}/${data.data.number}`;
      let index = data.data.date.indexOf('T');
      state.invoiceDate = data.data.date.substring(0, index);
    },

    /* CTT */
    SET_SHIPMENT_CTT(sate, data) {
      console.log(data);
    },
    SET_SHIPMENT_CTT_EXTRA(state, data) {
      state.cttShipment = data.objectNumber;
      state.zpl = data.zpl;
      state.cttTracking = data.tracking;
      console.log(data);
      console.log(data.zpl);
    },
    SET_LABEL_CTT(state, data) {
      state.cttLabel = data;
    },
    SET_ALL_CTT_CLOSE(state, data) {
      console.log(data.data);
      state.allCloseCTT = data.data;
    },
    SET_VALID_CTT(state, data) {
      state.validCtt = data.data;
    },
    UPDATE_VALID_CTT() {},
    SET_DATA_CTT_CLOSE(state, data) {
      state.cttOrdersClose = data.orders;
      state.cttDataClose = data.closeShippings;
    },
    /* CTTEXPRESSO */
    SET_SHIPMENT_CTTEXPRESSO(state, data) {
      state.cttExpressoExpedition = data.objectNumber;
      console.log(state.cttExpressoExpedition);
      console.log(data);
    },
    SET_SHIPMENT_CTTEXPRESSO_48(state, data) {
      state.cttExpressoExpedition = data.objectNumber;
      console.log(state.cttExpressoExpedition);
      console.log(data);
    },
    SET_SHIPMENT_CTTEXPRESSO_INTERNATIONAL(state, data) {
      state.cttExpressoExpedition = data.objectNumber;
      console.log(data);
    },
    SET_LABEL_CTTEXPRESSO(state, data) {
      console.log(data);
      state.cttExpressoLabel = data;
    },
    SET_CTT_EXPRESSO_CLOSE(state, data) {
      console.log(data.file);
      state.cttExpressoCloseLabel = data.file;
      window.open('data:application/pdf;base64,' + data.file, '_blank');
    },
    SET_CTT_EXPRESSO_SHIPMENTS(state, data) {
      console.log(data);
      state.cttExpressoShipments = data.data;
    },
    SET_CTT_SHIPMENTS(state, data) {
      console.log(data);
      state.cttShipments = data.data;
    },
    DELETE_CTT_EXPRESSO_SHIPMENT(state, data) {
      console.log(data);
    },
    // FedEx
    SET_SHIPMENT_FEDEX(state, data) {
      state.fedexData = data.data;
    },
    SET_CANCEL_SHIPMENT(state, data) {
      state.message = data.data;
    },
    SET_CURRENCY_FEDEX(state, data) {
      state.fedexCurrency = data.data.currency;
      console.log('CURRENCY DATA:', state.fedexCurrency);
    },
    /* EMAIL */
    POST_EMAIL(state, data) {
      console.log(data);
    },
    /* SUPPLIERS */
    ADD_INVOICE_SUPPLIERS(state, data) {
      state.message = data.message;
      state.id_invoice = data.id_invoice;
      console.log(data);
    },
    UPDATE_INVOICE_SUPPLIERS(state, data) {
      state.message = data.message;
      console.log(data);
    },
    SET_INVOICE_SUPPLIERS(state, data) {
      state.suppliersInvoice = data.data;
    },
    SET_INVOICE_SUPPLIERS_ID(state, data) {
      state.suppliersInvoiceID = data.data;
    },
    SET_BRANDS(state, data) {
      state.allBrands = data.data;
    },
    SET_SUPPLIERS(state, data) {
      console.log(data);
      state.allSuppliers = data.data;
    },
    ADD_SUPPLIERS() {},
    UPDATE_SUPPLIERS() {},
    ADD_PRODUCTS(state, data) {
      console.log(data);
    },
    ADD_PRODUCT_INFO(state, data) {
      state.productExtraInfo = data.data;
    },
    REMOVE_INVOICE_SUPPLIER(state, data) {
      console.log(data);
    },
    SEARCH_RECEPTION(state, data) {
      console.log(data.data);
      state.searchInvoiceSuppliers = data.data;
    },
    /* ###############PURCHASES#################### */
    SET_PRODUCTS_TRANS(state, data) {
      state.prodTransactions = data.data;
      console.log(state.orderProducts);
    },
    SET_PRODUCTS_EXTRA_INFO(state, data) {
      state.prodExtraInfo = data.data[0];
    },
    SET_PURCHASES_TRANSACTIONS(state, data) {
      console.log('PURCHASE INDEX: ', data.data);
      state.purchasesTransactions = data.data;
    },
    SET_PURCHASES_PRODUCTS_MISSING(state, data) {
      state.productsMissing = data.data;
      state.productsMissingLocked = data.data_lock;
      state.productsMissingDate = data.update;
    },
    SET_PURCHASES_PRODUCTS_REQUESTED(state, data) {
      state.productsTransactions = data.data;
    },
    SET_PURCHASES_FARMA_ORDERS(state, data) {
      state.farmaOrders = data.data;
    },
    SET_PURCHASES_FARMA_ORDERSCONF(state, data) {
      console.log(data.data);
      state.farmaOrdersConf = data.data;
    },
    ADD_TRANSACTIONS_PURCHASE(state, data) {
      console.log(data.id);
      state.idTransaction = data.id;
    },
    ADD_TRANSACTIONS_CONFIRM() {},
    ADD_TRANSACTIONS_UPDATE() {},
    SEND_EMAIL_MISS() {},
    UPDATE_PRODUCTS_DB() {},
    ADD_TRANSACTIONS_INVOICE() {},
    ADD_TRANSACTIONS_INVOICE_EXTERN() {},
    ADD_NOTES() {},
    SET_ORDER_PENDENT() {},
    SET_TRACKING(state, data) {
      state.tracking = data.data;
    },
    SET_TRACKING_NOT_DEL(state, data) {
      state.ordersNotDel = data.data;
    },
    CALCULATE_PICKING() {},
    UPDATE_PURCHASE() {},
    PICKING_BY_ID(state, data) {
      state.pickingByID = data.data;
    },
    SET_ORDER_PRIORITY() {},
    SEND_ORDER_EMAIL() {},
    PURCHASE_PRODUCT(state, data) {
      state.purchaseProducts = data.data;
    },
    BACK_ORDER() {},

    //NEW ORDERS
    SET_ORDERS_PENDENT(state, data) {
      state.ordersPendent = data.data;
    },
    SET_ORDERS_PENDENTCS(state, data) {
      state.ordersPendentCS = data.data;
    },
    SET_ORDERS_PENDENTEDIT(state, data) {
      state.ordersPendentEdit = data.data;
    },
    SET_ORDERS_PROCESSING_NEW(state, data) {
      state.ordersProcessing = data.data;
    },
    SET_ORDERS_PICKING_NEW(state, data) {
      state.ordersPicking = data.data;
    },
    SET_ORDERS_PRINTING(state, data) {
      state.ordersPrinting = data.data;
    },
    SET_ORDERS_PACKING(state, data) {
      state.ordersPacking = data.data;
    },
    SET_ORDERS_RETURNED(state, data) {
      state.ordersReturned = data.data;
    },
    SET_SHIPPING_VARIABLES(state, data) {
      state.shippingVariables = data.data;
    },
    SET_PRICE_VARIABLES(state, data) {
      state.priceVariables = data.data;
    },
    SET_PRICE_INTERVALOS(state, data) {
      state.priceIntervalos = data.data;
    },
    SET_SHIPPING_CALCULATED(state, data) {
      state.shippingMessage = data.message;
      state.shippingCalculated = data.data;
    },
    DO_NOTHING() {},
    SET_ALL_ORDERS_PROCESSING(state, data) {
      state.allOrdersProcessing = data.data;
    },
    SET_SALES_REPORT(state, data) {
      state.salesReport = data.data;
    },
    SET_SALES_REPORT_PICKED(state, data) {
      state.salesReportPicked = data.data;
    },
    SET_SALES_REPORT_ANUAL(state, data) {
      state.salesReportAnual = data.data;
    },
    SET_SALES_REPORT_COUNTRY(state, data) {
      state.salesReport = data.data;
    },
    SET_SALES_REPORT_COUNTRY_DATE(state, data) {
      state.salesReportPicked = data.data;
    },
    RETURNED_PRODUCTS(state, data) {
      console.log(data);
      state.returnedProducts = data.data;
    },
    SET_DAYS_TO_DELIVERED(state, data) {
      state.countingDays = data.data;
    },
    SET_DAYS_TO_EVALUATION(state, data) {
      state.daysEvaluation = data.data;
    },
    EDIT_DAYS_TO_EVALUATION(state, data) {
      console.log(data.data);
    },
    EDIT_DAYS_TO_DELIVERED(state, data) {
      console.log(data.data);
    },
    DELETE_EMAIL(state, data) {
      console.log(data.data);
    },
    SET_EMAIL_MARKETING(state, data) {
      state.email = data.data;
    },
    EDIT_EMAIL_MARKETING(state, data) {
      console.log(data);
    },
    SET_VALIDITY_REPORTS(state, data) {
      console.log(data.data);
      state.reportsValidity = data.data;
    },
    RETURN_INTERN(state, data) {
      console.log(data.data);
    },
    ADD_EMAIL_EVALUATION(sate, data) {
      console.log(data.data);
    },
    LOST_ORDER(state, data) {
      console.log(data.data);
    },
    DUPLICATE_ORDER(state, data) {
      console.log(data.data);
    },
    SET_ORDERS_LOST(state, data) {
      state.ordersLosted = data.data;
    },
    SET_ORDERS_VALIDATE_ADDRESS(state, data) {
      console.log(data.data);
      state.ordersValidateAddress = data.data;
    },
    EDIT_STATUS(state, data) {
      console.log(data.message);
    },
    SET_APPROVE_ADDRESS(state, data) {
      console.log(data.message);
    },
    SET_EMAIL_VALIDATION_ADDRESS(state, data) {
      console.log(data);
    },
    SET_VALIDATION_ADDRESS_STATUS(state, data) {
      console.log(data.message);
    },
    SET_VALIDATION_ADDRESS(state, data) {
      state.validationAddress = data.data;
    },
    SET_REASONS_DELAY(state, data) {
      state.reasonsDelay = data.data;
    },
    //DISCOUNTS
    SET_DISCOUNTS(state, data) {
      state.discounts = data.data;
    },
    SET_DISCOUNT_PRODUCTS(state, data) {
      state.discountProducts = data.data;
    },
    SET_STOCK_STATUS_VAR(state, data) {
      state.stockStatusVar = data.data;
    },
    SET_PRICE_OPTIONS_VAR(state, data) {
      state.priceOptionsVar = data.data;
    },
    SET_STOCK_UPDATE_VAR(state, data) {
      state.stockUpdateVar = data.data;
    },
    //FEED
    SET_ALL_FEED_VALUES(state, data) {
      console.log(data.data);
      state.allFeedValues = data.data;
    },
    SET_ALL_FEED_SETTINGS(state, data) {
      state.allFeedSettings = data.data;
      console.log(data.data);
    },
    EDIT_FEED_SETTINGS(state, data) {
      console.log(data);
    },
    EDIT_FEED_VALUES(state, data) {
      console.log(data);
    },
    ADD_FEED_INTERVAL(state, data) {
      console.log(data);
    },
    SET_FEED_INTERVAL(state, data) {
      state.intervalFeed = data.data;
      console.log('Margem ', data);
    },
    SET_BY_FEED_INTERVAL(state, data) {
      state.margemByFeed = data.data;
      console.log(data);
    },
    DELETE_FEED_INTERVALS(state, data) {
      console.log(data);
    },
    SET_MISSING_PRODUCTS_BRANDS(state, data) {
      state.missingBrands = data.data;
      console.log(data);
    },
    SET_MANUAL_PRICE(state, data) {
      state.manualPrices = data.data;
      console.log(data);
    },
    EDIT_PRODUCT_BRAND(state, data) {
      console.log(data);
    },
    SET_FEED_SHIPPING(state, data) {
      state.shippingFeed = data.data;
      console.log(data);
    },
    ADD_FEED_SHIPPING(state, data) {
      console.log(data);
    },
    EDIT_FEED_INTERVALS(state, data) {
      console.log(data);
    },
    SET_FEED_MARGEM(state, data) {
      state.margensFeed = data.data;
      console.log(data);
    },
    DELETE_FEED_MARGEM(state, data) {
      console.log(data);
    },
    DELETE_FEED_SHIPPING(state, data) {
      console.log(data);
    },
    ADD_MARGEM_FEED(state, data) {
      console.log(data);
    },
    ADD_NO_PRODUCT_FEED(state, data) {
      console.log(data);
    },
    SET_NO_PRODUCT_FEED(state, data) {
      state.noProductFeed = data.data;
      console.log(data);
    },
    DELETE_NO_PRODUCT_FEED(state, data) {
      console.log(data);
    },
    FILTER_NO_PRODUCT_FEED(state, data) {
      state.noProductFeed = data.data;
      console.log(data);
    },
    SET_AEROSOL_REPORT(state, data) {
      state.aerosolReport = data.data;
      console.log(data);
    },
    EDIT_AEROSOL_REPORT(state, data) {
      console.log(data);
    },
    ADD_CLIENT_REQUEST(state, data) {
      console.log(data.data);
    },
    SET_REQUEST_CLIENT(state, data) {
      state.requestClient = data.data;
    },
    SET_ALL_REQUESTS_CLIENT(state, data) {
      state.allRequestsClient = data.data;
    },
    ASSOCIATION_CLIENT_REQUEST(state, data) {
      console.log(data.data);
    },
    UPDATE_CLIENT_OFFER(state, data) {
      console.log(data.data);
    },
    UPDATE_COUNTER_OFFER(state, data) {
      console.log(data.data);
    },
    CANCEL_CLIENT_REQUEST(state, data) {
      console.log(data.data);
    },
    ACCEPT_CLIENT_REQUEST(state, data) {
      console.log(data.data);
    },
    FILTER_CLIENT_REQUEST(state, data) {
      state.filterClientRequests = data.data;
    },
    SET_LAST_UPDATED_CLIENT_REQUEST(state, data) {
      state.lastClientRequest = data.data;
    },
    SET_ALL_LAST_UPDATED_REQUESTS(state, data) {
      state.allRequestsClient = data.data;
    },
    FILTER_ALL_REQUEST(state, data) {
      state.allRequestsClient = data.data;
    },
    SET_NEW_COMMENT_REQUEST(sate, data) {
      console.log(data.data);
    },
    EDIT_REQUEST_CLIENT(state, data) {
      console.log(data.data);
    },
    SET_PRODUCT_CLIENT(state, data) {
      state.productClient = data.data;
    },
    SET_ALL_PRODUCT_CLIENT(state, data) {
      state.allProductClient = data.data;
    },
    ADD_CLIENT_PRODUCT(state, data) {
      console.log(data.data);
    },
    EDIT_PRODUCT_CLIENT(state, data) {
      console.log(data.data);
    },
    CREATE_ORDER_TO_CLIENT_REQUEST(state, data) {
      console.log(data.data);
    },
    GET_CLIENT_ADDRESS_REQUEST(state, data) {
      state.searchClientAddress = data.data;
    },
    GET_COUNT_REQUEST_EMAIL(state, data) {
      state.countRequestEmail = data.data;
    },
    REQUETS_CLIENT_CREATE_ORDER(state, data) {
      console.log(data.data);
    },
    EDIT_FINANCIAL_SHEET(state, data) {
      console.log(data.data);
    },
    CANCEL_REQUEST_CLIENT(state, data) {
      console.log(data.data);
    },
    SET_CLIENT_REQUEST_SENT(state, data) {
      state.clientRequestSent = data.data;
    },
    SET_CLIENT_DISCOUNT(state, data) {
      state.clientDiscount = data.data;
    },
    EDIT_CLIENT_DISCOUNT(state, data) {
      console.log(data.data);
    },
    SET_CLIENT_SHIPPING_ORDER(state, data) {
      state.clientOrderShipping = data.data;
    },
    //Clients Orders
    SET_SEARCH_CLIENTS(state, data) {
      state.searchClients = data.data;
    },
    SET_CLIENT_INFO(state, data) {
      state.clientInfo = data.data;
    },
    //Translated
    SET_ALL_TRANSLATED(state, data) {
      state.allTranslated = data.data;
    },
    SET_PRODUCTS_CLIENT_PRICE(state, data) {
      state.productsClientPrice = data.data;
    },
    SET_ALL_CLIENT_PRODUCTS_PRICE(state, data) {
      state.listProductsClientPrice = data.data;
    },
    EDIT_CLIENT_PRODUCT_PRICE(state, data) {
      console.log(data.data);
    },
    REMOVE_CLIENT_PRODUCT_PRICE(state, data) {
      console.log(data.data);
    },
    EDIT_PRODUCT_IMAGE(state, data) {
      console.log(data.data);
    },
    // Daniela Platform
    SET_ALL_BRANDS_PURCHASE(state, data) {
      state.allBrandsPurchase = data.data;
    },
    SET_NEW_TRANSACTIONS_AFTER_BRANDS_PURCHASE(state, data) {
      console.log(data.data);
    },
    SET_EMAIL_TO_LAB(state, data) {
      console.log(data.data);
    },
    SET_ALL_LABORATORIES(state, data) {
      state.allLabs = data.data;
      console.log(data.data);
    },
    UPDATE_BRANDS_LAB(state, data) {
      console.log(data.data);
    },
    SET_ALL_DANIELA_PURCHASE(state, data) {
      state.allDanielaPurchase = data.data;
    },
    CANCEL_BRANDS_PURCHASE(state, data) {
      console.log(data.data);
    },
    SET_PURCHASES_LAB_FARMA(state, data) {
      state.farmasPurchaseLab = data.data;
    },
    EDIT_LAB_PURCHASES(state, data) {
      console.log(data.data);
    },
    FILTER_LABS_REQUESTS(state, data) {
      state.allDanielaPurchase = data.data;
    },
    EDIT_LAB_LATE_PURCHASES(state, data) {
      console.log(data.data);
    },
    CONFIRM_LAB_PURCHASE(state, data) {
      console.log(data.data);
    },
    EDIT_REQUEST_QUANTITY(state, data) {
      console.log(data.data);
    },
    CREATE_CREDIT_NOTE(state, data) {
      console.log(data.data);
    },
    //IMPORTS
    INSERT_FILE_CSV(state, data) {
      console.log(data.errorSKUs);
      state.errorSKUS = data.errorSKUs;
      state.errorUpdate = data.errorUpdate;
      state.errorLimitDate = data.errorLimit;
    },
    SET_IMPORT_FILES(state, data) {
      console.log(data.data);
      state.allImportFiles = data.data;
    },
    SET_IMPORT_TEMPLATES(state, data) {
      this.importFile = data;
    },
    //INSURANCE
    ADD_INSURANCE(state, data) {
      console.log(data.data);
    },
    FETCH_ALL_INSURANCE(state, data) {
      console.log(data.data);
      state.allInsurances = data.data;
    },
    FETCH_FILE_IMPORTED(state, data) {
      console.log(data);
    },
    //CATEGORIES
    SET_CATEGORIES(state, data) {
      state.allCategories = data.data;
    },
    //PRODUCTS
    SET_PRODUCTS_CATEGORIES(state, data) {
      state.product_categories = data.data;
    },
    //FEEDS
    SET_FEEDS_COUNTRY(state, data) {
      state.allFeeds = data.data;
    },
    //COINS
    SET_COINS(state, data) {
      state.allCoins = data.data;
    },
    //CUSTOM LABELS
    SET_CUSTOM_LABELS(state, data) {
      state.customLabels = data.data;
    },
    CANCEL_FARMAS_TRANSACTIONS(state, data) {
      console.log(data.data);
    },
    WAS_INTERN_RETURN(state, data) {
      console.log(data.data.length);
      if (data.data.length > 0) {
        state.wasInternReturn = true;
      }
    },
    SET_EMAIL_TO_SUPPLIER_TO_INVOICE(state, data) {
      console.log(data.data);
    },
    ADD_IMG_RECEPTION(state, data) {
      console.log(data.data);
    },
    ADD_FILE_TO_RECEPTION(state, data) {
      console.log(data.data);
    },
    CTT_TRACKINGS_ORDER(state, data) {
      console.log(data.data);
      state.cttTrackinsOrders = data.data;
    },
    //Carina
    SET_CARINA_EMAILS(state, data) {
      state.carinaEmails = data.data;
    },
    SET_DELIVERY_NOTE_CTT(state, data) {
      console.log(data.data);
    },
    GET_DELIVERY_NOTE_CTT(state, data) {
      state.deliveriesNotesCtt = data.data;
      console.log(data.data);
    },
    SET_ALL_SUPPLIERS_WITH_DIFFERENCE(state, data) {
      console.log(data.data);
      state.suppliersWithDifference = data.data;
    },
    SET_ACCOUNTING_SUPPLIER(state, data) {
      console.log(data.data);
      state.accountingSuppliers = data.data;
    },
    ADD_ACCOUNTING(state, data) {
      console.log(data.data);
    },
    SET_ALL_RECEPTIONS_BY_SUPPLIER_IN_ACCOUNTING(state, data) {
      console.log(data.data);
      state.allReceptionsAccounting = data.data;
    },
    SET_SUPPLIER_BY_ID(state, data) {
      state.supplier = data.data;
    },
    SET_RECEPTIONS_TO_RECEIVE(state, data) {
      state.receptionsToAccounting = data.data;
    },
    SET_RECEPTIONS_TO_CREDIT_NOTE(state, data) {
      state.receptionsToAccounting = data.data;
    },
    SET_SEARCH_BILL(state, data) {
      state.receptionsToAccounting = data.data;
    },
    ADD_CREDIT_NOTE_FILE(state, data) {
      console.log(data.data);
    },
    SET_RECEPTIONS_TO_ACCOUNTING(state, data) {
      state.suppliersWithDifference = data.data;
    },
    SEARCH_ACCOUNTING_DETAILS(state, data) {
      console.log(data.data);
      state.accountingDetails = data.data;
    },
    SET_RECEPTIONS_TO_PAYMENT(state, data) {
      console.log(data.data);
      state.receptionsToAccounting = data.data;
    },
    SET_ACTIVATE_CREDIT_NOTE(state, data) {
      console.log(data.data);
    },
  },
  actions: {
    async activateCreditNote({ commit }, payload) {
      commit('SET_ACTIVATE_CREDIT_NOTE', await idiviaService.activateCreditNote(payload.id));
    },
    async getDeliveryNoteCTT({ commit }) {
      commit('GET_DELIVERY_NOTE_CTT', await idiviaService.getDeliveryNoteCTT());
    },
    async addDeliveryNoteCTT({ commit }, payload) {
      commit('SET_DELIVERY_NOTE_CTT', await idiviaService.addDeliveryNoteCTT(payload.date, payload.file));
    },
    async getReceptionsToPay({ commit }, payload) {
      commit('SET_RECEPTIONS_TO_PAYMENT', await idiviaService.getReceptionsToPay(payload.id));
    },
    async searchByDetailsAccounting({ commit }, payload) {
      commit(
        'SEARCH_ACCOUNTING_DETAILS',
        await idiviaService.searchByDetailsAccounting(payload.date_start, payload.date_end, payload.document)
      );
    },
    async filterBySupplierAccounting({ commit }, payload) {
      commit('SET_RECEPTIONS_TO_ACCOUNTING', await idiviaService.filterBySupplierAccounting(payload.id));
    },
    async addCreditNoteFile({ commit }, payload) {
      commit(
        'ADD_CREDIT_NOTE_FILE',
        await idiviaService.addCreditNoteFile(
          payload.id,
          payload.file,
          payload.creditNoteNumber,
          payload.creditNoteValue,
          payload.supplier
        )
      );
    },
    async searchByBill({ commit }, payload) {
      commit('SET_SEARCH_BILL', await idiviaService.searchByBill(payload.id, payload.search));
    },
    async receptionsToCreditNote({ commit }, payload) {
      commit('SET_RECEPTIONS_TO_CREDIT_NOTE', await idiviaService.receptionsToCreditNote(payload.id));
    },
    async receptionsToReceive({ commit }, payload) {
      commit('SET_RECEPTIONS_TO_RECEIVE', await idiviaService.receptionsToReceive(payload.id));
    },
    async cttTrackingsOrder({ commit }, payload) {
      commit('CTT_TRACKINGS_ORDER', await idiviaService.cttTrackingsOrder(payload.id));
    },
    async addFileReceptions({ commit }, payload) {
      commit('ADD_FILE_TO_RECEPTION', await idiviaService.addFileReceptions(payload.id, payload.file));
    },
    async addImageProductsReceived({ commit }, payload) {
      commit('ADD_IMG_RECEPTION', await idiviaService.addImageProductsReceived(payload.id, payload.image));
    },
    async supplierByID({ commit }, payload) {
      commit('SET_SUPPLIER_BY_ID', await idiviaService.supplierByID(payload.id));
    },
    async accountingAllReceptions({ commit }, payload) {
      commit('SET_ALL_RECEPTIONS_BY_SUPPLIER_IN_ACCOUNTING', await idiviaService.accountingAllReceptions(payload.id));
    },
    async addAccounting({ commit }, payload) {
      commit(
        'ADD_ACCOUNTING',
        await idiviaService.addAccounting(
          payload.document,
          payload.supplier,
          payload.value,
          payload.reception_id,
          payload.invoice,
          payload.date
        )
      );
    },
    async accountingBySupplier({ commit }, payload) {
      commit(
        'SET_ACCOUNTING_SUPPLIER',
        await idiviaService.accountingBySupplier(payload.id, payload.date_start, payload.date_end)
      );
    },
    async accountingAllSuppliersWithDifference({ commit }) {
      commit('SET_ALL_SUPPLIERS_WITH_DIFFERENCE', await idiviaService.accountingAllSuppliersWithDifference());
    },
    async requestForInvoiceToSupplier({ commit }, payload) {
      commit(
        'SET_EMAIL_TO_SUPPLIER_TO_INVOICE',
        await idiviaService.requestForInvoiceToSupplier(payload.id, payload.email, payload.products)
      );
    },
    async checkIfWasInternReturn({ commit }, payload) {
      commit('WAS_INTERN_RETURN', await idiviaService.checkIfWasInternReturn(payload.id));
    },
    async cancelFarmasTransactions({ commit }, payload) {
      commit('CANCEL_FARMAS_TRANSACTIONS', await idiviaService.cancelFarmasTransactions(payload.ids));
    },
    async getFileImported({ commit }, payload) {
      commit('FETCH_FILE_IMPORTED', await idiviaService.getFileImported(payload.fileName));
    },
    async downloadImports({ commit }, payload) {
      commit('SET_IMPORT_TEMPLATES', await idiviaService.downloadImports(payload.type));
    },
    async allImports({ commit }) {
      commit('SET_IMPORT_FILES', await idiviaService.allImports());
    },
    async allInsurance({ commit }) {
      commit('FETCH_ALL_INSURANCE', await idiviaService.allInsurance());
    },
    async insertInsurance({ commit }, payload) {
      commit('ADD_INSURANCE', await idiviaService.addInsurance(payload.tracking, payload.value));
    },
    async insertFileCSV({ commit }, payload) {
      commit('INSERT_FILE_CSV', await idiviaService.insertFileCSV(payload.file, payload.type));
    },
    async createCreditNote({ commit }, payload) {
      commit('CREATE_CREDIT_NOTE', await idiviaService.creditNote(payload.idOrder, payload.idInvoice, payload.notes));
    },
    async editRequestQuantity({ commit }, payload) {
      commit('EDIT_REQUEST_QUANTITY', await idiviaService.editQtyRequestLab(payload.id_trans, payload.newQty));
    },
    async confirmLabPurchase({ commit }, payload) {
      commit('CONFIRM_LAB_PURCHASE', await idiviaService.confirmDanielaPurchase(payload.id_trans, payload.id_daniela));
    },
    async lateLabPurchases({ commit }, payload) {
      commit('EDIT_LAB_LATE_PURCHASES', await idiviaService.lateLabPurchases(payload.id_trans, payload.id_daniela));
    },
    async dataCttClose({ commit }, payload) {
      commit('SET_DATA_CTT_CLOSE', await idiviaService.dataCttClose(payload.shipments));
    },
    async filterRequestsAndPicking({ commit }, payload) {
      commit('FILTER_ALL_REQUEST', await idiviaService.filterRequestsAndPicking(payload.email));
    },
    async editProductImage({ commit }, payload) {
      commit('EDIT_PRODUCT_IMAGE', await idiviaService.editProductImage(payload.id, payload.img));
    },
    async removeClientProductPrice({ commit }, payload) {
      commit('REMOVE_CLIENT_PRODUCT_PRICE', await idiviaService.removeClientsProductsPrice(payload.id));
    },
    async updateClientsProductsPrice({ commit }, payload) {
      commit(
        'EDIT_CLIENT_PRODUCT_PRICE',
        await idiviaService.updateClientsProductsPrice(
          payload.id,
          payload.price_eur,
          payload.active,
          payload.updatePrice
        )
      );
    },
    async allClientsInProductsPrice({ commit }) {
      commit('SET_ALL_CLIENT_PRODUCTS_PRICE', await idiviaService.allClientsInProductsPrice());
    },
    async allProductsClientPrice({ commit }, payload) {
      commit(
        'SET_PRODUCTS_CLIENT_PRICE',
        await idiviaService.allProductsClientPrice(payload.client, payload.sku, payload.active)
      );
    },
    async filterLabsPurchases({ commit }, payload) {
      commit('FILTER_LABS_REQUESTS', await idiviaService.filterLabsPurchases(payload.lab, payload.status, payload.sku));
    },
    async updateLabPurchase({ commit }, payload) {
      commit(
        'EDIT_LAB_PURCHASES',
        await idiviaService.updateLabPurchase(
          payload.sku,
          payload.newQty,
          payload.to_alvim,
          payload.to_martins,
          payload.to_gomes,
          payload.to_perelhal,
          payload.to_idivia,
          payload.id,
          payload.idTransaction
        )
      );
    },
    async farmasLabPurchase({ commit }) {
      commit('SET_PURCHASES_LAB_FARMA', await idiviaService.farmasLabPurchase());
    },
    async cancelBrandsPurchase({ commit }, payload) {
      commit('CANCEL_BRANDS_PURCHASE', await idiviaService.cancelBrandsPurchase(payload.id));
    },
    async danielaPurchase({ commit }) {
      commit('SET_ALL_DANIELA_PURCHASE', await idiviaService.danielaPurchase());
    },
    async updateBrandsLab({ commit }, payload) {
      commit('UPDATE_BRANDS_LAB', await idiviaService.updateBrandLab(payload.id, payload.lab));
    },
    async allLaboratories({ commit }) {
      commit('SET_ALL_LABORATORIES', await idiviaService.allLaboratories());
    },
    async sendEmailToLab({ commit }, payload) {
      commit('SET_EMAIL_TO_LAB', await idiviaService.sendEmailToLab(payload.email, payload.lab, payload.products));
    },
    async getProductToAddBrandsPurchase({ commit }, payload) {
      commit('SET_PRODUCTS_SKU', await idiviaService.addProductToBrandsPurchase(payload.sku));
    },
    async createNewTransactionsFromBrandsPurchase({ commit }, payload) {
      commit(
        'SET_NEW_TRANSACTIONS_AFTER_BRANDS_PURCHASE',
        await idiviaService.createNewTransactionsFromBrandsPurchase(payload.products)
      );
    },
    async setAllBrandsPurchase({ commit }) {
      commit('SET_ALL_BRANDS_PURCHASE', await idiviaService.getAllBrandPurchase());
    },
    async searchClientOrders({ commit }, payload) {
      commit('SET_ORDERS_FILTER', await idiviaService.searchClientOrders(payload.status, payload.id));
    },
    async filterClientOrders({ commit }, payload) {
      commit('SET_ORDERS_FILTER', await idiviaService.filterClientOrders(payload.status));
    },
    async clientShippingOrder({ commit }, payload) {
      commit('SET_CLIENT_SHIPPING_ORDER', await idiviaService.getClientOrderShipping(payload.id));
    },
    async getOrderClient({ commit }, payload) {
      commit('SET_ORDER_BY_ID', await idiviaService.getClientOrder(payload.id));
    },
    async logsClientRequest({ commit }, payload) {
      commit('SET_LOGS', await idiviaService.logsClientRequest(payload.id));
    },
    async allOrdersClient({ commit }) {
      commit('SET_ORDERS', await idiviaService.getAllOrdersClient());
    },
    async updateDiscountClients({ commit }, payload) {
      commit('EDIT_CLIENT_DISCOUNT', await idiviaService.updateDiscountClients(payload.id, payload.discount));
    },
    async allClientsDiscounts({ commit }) {
      commit('SET_CLIENT_DISCOUNT', await idiviaService.allClientsDiscounts());
    },
    async clientDiscounts({ commit }, payload) {
      commit('SET_CLIENT_DISCOUNT', await idiviaService.getClienteDiscount(payload.email));
    },
    async backinStatusRequestClient({ commit }, payload) {
      commit(
        'EDIT_REQUEST_CLIENT',
        await idiviaService.backinStatusRequestClient(payload.id, payload.status, payload.email)
      );
    },
    async sentRequestClient({ commit }, payload) {
      commit('SET_CLIENT_REQUEST_SENT', await idiviaService.sentRequestClient(payload.id));
    },
    async cancelRequestClient({ commit }, payload) {
      commit('CANCEL_REQUEST_CLIENT', await idiviaService.cancelRequestClient(payload.id, payload.cancelStatus));
    },
    async financialSheet({ commit }, payload) {
      commit('EDIT_FINANCIAL_SHEET', await idiviaService.financialSheet(payload.id, payload.finance_sheet));
    },
    async requestClientCreateOrder({ commit }, payload) {
      commit(
        'REQUETS_CLIENT_CREATE_ORDER',
        await idiviaService.requestClientCreateOrder(
          payload.email,
          payload.firstName,
          payload.lastName,
          payload.phone,
          payload.address1,
          payload.address2,
          payload.city,
          payload.state,
          payload.postCode,
          payload.country,
          payload.orderProducts,
          payload.shippingCompany,
          payload.total,
          payload.shippingLines,
          payload.backupProduct,
          payload.exchange
        )
      );
    },
    async countEmailRequests({ commit }) {
      commit('GET_COUNT_REQUEST_EMAIL', await idiviaService.countEmailRequest());
    },
    async searchClientAddressRequest({ commit }, payload) {
      commit('GET_CLIENT_ADDRESS_REQUEST', await idiviaService.searchClientAddress(payload.email));
    },
    async createOrderToClientRequest({ commit }, payload) {
      commit('CREATE_ORDER_TO_CLIENT_REQUEST', await idiviaService.createOrderToClientRequest(payload.products));
    },
    async editClientProduct({ commit }, payload) {
      commit(
        'EDIT_PRODUCT_CLIENT',
        await idiviaService.editProductClient(
          payload.sku,
          payload.name,
          payload.img,
          payload.product_link,
          payload.price_sale
        )
      );
    },
    async addClientProduct({ commit }, payload) {
      commit(
        'ADD_CLIENT_PRODUCT',
        await idiviaService.addProductClient(payload.sku, payload.name, payload.img, payload.product_link)
      );
    },
    async getAllProductClient({ commit }) {
      commit('SET_ALL_PRODUCT_CLIENT', await idiviaService.allProductClient());
    },
    async getClientProduct({ commit }, payload) {
      commit('SET_PRODUCT_CLIENT', await idiviaService.getClientProduct(payload.sku));
    },
    async filterNoProductsFeed({ commit }, payload) {
      commit('FILTER_NO_PRODUCT_FEED', await idiviaService.filterNoProductsFeed(payload.sku, payload.feed));
    },
    async deleteNoProductFeed({ commit }, payload) {
      commit('DELETE_NO_PRODUCT_FEED', await idiviaService.deleteNoProductFeed(payload.id));
    },
    async noProductFeed({ commit }) {
      commit('SET_NO_PRODUCT_FEED', await idiviaService.getNoProductsFeed());
    },
    async addNoProductFeed({ commit }, payload) {
      commit(
        'ADD_NO_PRODUCT_FEED',
        await idiviaService.noProductsFeed(payload.sku, payload.feed, payload.nameProduct, payload.id_product)
      );
    },
    async editRequestClient({ commit }, payload) {
      commit(
        'EDIT_REQUEST_CLIENT',
        await idiviaService.editRequestClient(
          payload.id,
          payload.img,
          payload.name,
          payload.sku,
          payload.price_sale,
          payload.product_link,
          payload.type,
          payload.qty,
          payload.counterOffer,
          payload.priceOffer,
          payload.price,
          payload.qty_accepted
        )
      );
    },
    async addCommentToRequest({ commit }, payload) {
      commit(
        'SET_NEW_COMMENT_REQUEST',
        await idiviaService.addCommentToRequest(payload.id, payload.comment, payload.oldComments)
      );
    },
    async filterAllRequests({ commit }, payload) {
      commit(
        'FILTER_ALL_REQUEST',
        await idiviaService.filterAllRequests(payload.status, payload.sku, payload.email, payload.name)
      );
    },
    async allLastUpdatedRequests({ commit }) {
      commit('SET_ALL_LAST_UPDATED_REQUESTS', await idiviaService.allLastUpdateRequests());
    },
    async lastUpdatedClientRequest({ commit }) {
      commit('SET_LAST_UPDATED_CLIENT_REQUEST', await idiviaService.getClientRequestLastUpdated());
    },
    async filterClientRequest({ commit }, payload) {
      commit(
        'FILTER_CLIENT_REQUEST',
        await idiviaService.filterClientRequests(payload.status, payload.sku, payload.name)
      );
    },
    async acceptRequest({ commit }, payload) {
      commit(
        'ACCEPT_REQUEST',
        await idiviaService.acceptRequest(payload.id, payload.status, payload.price_final, payload.email)
      );
    },
    async cancelClientRequest({ commit }, payload) {
      commit('CANCEL_CLIENT_REQUEST', await idiviaService.cancelClientRequest(payload.id, payload.status));
    },
    async updateClientCounterOffer({ commit }, payload) {
      commit(
        'UPDATE_COUNTER_OFFER',
        await idiviaService.updateClientCounterOffer(payload.id, payload.status, payload.counter_offer)
      );
    },
    async updateClientOffer({ commit }, payload) {
      commit(
        'UPDATE_CLIENT_OFFER',
        await idiviaService.updateClientOffer(
          payload.id,
          payload.status,
          payload.price_offer,
          payload.qty_accepted,
          payload.actual_status,
          payload.save_price,
          payload.id_backoffice,
          payload.client_email
        )
      );
    },
    async associateProduct({ commit }, payload) {
      commit(
        'ASSOCIATION_CLIENT_REQUEST',
        await idiviaService.associateProduct(payload.id, payload.id_backoffice, payload.sku_client, payload.email)
      );
    },
    async getAllClientRequests({ commit }) {
      commit('SET_ALL_REQUESTS_CLIENT', await idiviaService.getAllClientRequests());
    },
    async getClientRequests({ commit }) {
      commit('SET_REQUEST_CLIENT', await idiviaService.getClientRequests());
    },
    async addClientRequest({ commit }, payload) {
      commit(
        'ADD_CLIENT_REQUEST',
        await idiviaService.addClientRequest(
          payload.sku,
          payload.name,
          payload.img,
          payload.qty,
          payload.price_sale,
          payload.product_link,
          payload.status,
          payload.type,
          payload.comments,
          payload.id_backoffice,
          payload.urgent
        )
      );
    },
    async updateAerosolProduct({ commit }, payload) {
      commit('EDIT_AEROSOL_REPORT', await idiviaService.updateAerosolProduct(payload.id, payload.aerosol));
    },
    async aerosolProductsReport({ commit }) {
      commit('SET_AEROSOL_REPORT', await idiviaService.aerosolProductsReport());
    },
    async addMargemFeed({ commit }, payload) {
      commit(
        'ADD_MARGEM_FEED',
        await idiviaService.addMargemFeed(payload.feed, payload.margem, payload.maximo, payload.minimo, payload.country)
      );
    },
    async deleteFeedIntervals({ commit }, payload) {
      commit('DELETE_FEED_INTERVALS', await idiviaService.deleteIntervalFeed(payload.id));
    },
    async deleteFeedShipping({ commit }, payload) {
      commit('DELETE_FEED_SHIPPING', await idiviaService.deleteShippingFeed(payload.id));
    },
    async getMargemFeed({ commit }) {
      commit('SET_FEED_MARGEM', await idiviaService.getMargemFeed());
    },
    async updateFeedInterval({ commit }, payload) {
      commit(
        'EDIT_FEED_INTERVALS',
        await idiviaService.editMargemInterval(
          payload.id,
          payload.price_initial,
          payload.price_final,
          payload.price_mult,
          payload.price_sum,
          payload.price_fix,
          payload.weight_initial,
          payload.weight_final,
          payload.weight_mult,
          payload.weight_sum,
          payload.weight_fix,
          payload.margem
        )
      );
    },
    async addShippingFeed({ commit }, payload) {
      commit(
        'ADD_FEED_SHIPPING',
        await idiviaService.addShippingFeed(
          payload.feed,
          payload.shipping,
          payload.maximo,
          payload.minimo,
          payload.country
        )
      );
    },
    async getShippingFeed({ commit }) {
      commit('SET_FEED_SHIPPING', await idiviaService.getShippingFeed());
    },
    async updateProductBrand({ commit }, payload) {
      commit('EDIT_PRODUCT_BRAND', await idiviaService.updateProductBrand(payload.id, payload.brand));
    },
    async getMissingProductsBrands({ commit }) {
      commit('SET_MISSING_PRODUCTS_BRANDS', await idiviaService.getMissingProductsBrands());
    },
    async getManualPrice({ commit }) {
      commit('SET_MANUAL_PRICE', await idiviaService.getManualPrice());
    },
    async deleteFeedMargem({ commit }, payload) {
      commit('DELETE_FEED_MARGEM', await idiviaService.deleteMargemFeed(payload.id));
    },
    async getIntervalFeed({ commit }) {
      commit('SET_FEED_INTERVAL', await idiviaService.getIntervalFeed());
    },
    async getMargemByFeed({ commit }, payload) {
      commit('SET_BY_FEED_INTERVAL', await idiviaService.getMargemFeedByFeed(payload.feed));
    },
    async addIntervalFeed({ commit }, payload) {
      commit(
        'ADD_FEED_INTERVAL',
        await idiviaService.addIntervalFeed(
          payload.price_initial,
          payload.price_final,
          payload.price_mult,
          payload.price_sum,
          payload.price_fix,
          payload.weight_initial,
          payload.weight_final,
          payload.weight_mult,
          payload.weight_sum,
          payload.weight_fix,
          payload.margem,
          payload.feed,
          payload.country
        )
      );
    },
    async updateFeedSettings({ commit }, payload) {
      commit(
        'EDIT_FEED_SETTINGS',
        await idiviaService.updateFeedSettings(
          payload.feed,
          payload.ocp_stock,
          payload.nova_engel,
          payload.farmas_stock,
          payload.only_site
        )
      );
    },
    async updateFeedValues({ commit }, payload) {
      commit(
        'EDIT_FEED_VALUES',
        await idiviaService.updateFeedValues(
          payload.feed,
          payload.soma,
          payload.multiplo,
          payload.margem,
          payload.fee,
          payload.shipping
        )
      );
    },
    async getFeedValues({ commit }) {
      commit('SET_ALL_FEED_VALUES', await idiviaService.getAllFeedValues());
    },
    async getFeedSettings({ commit }) {
      commit('SET_ALL_FEED_SETTINGS', await idiviaService.getAllFeedSettings());
    },
    async addressValidation({ commit }) {
      commit('SET_VALIDATION_ADDRESS', await idiviaService.getValidationAddress());
    },
    async addressValidationStatus({ commit }, payload) {
      commit('SET_VALIDATION_ADDRESS_STATUS', await idiviaService.addressValidationStatus(payload.status));
    },
    async sendEmailToApproveAddress({ commit }, payload) {
      commit(
        'SET_EMAIL_VALIDATION_ADDRESS',
        await idiviaService.sendEmailToApproveAddress(
          payload.id,
          payload.email,
          payload.firstName,
          payload.secondName,
          payload.address,
          payload.city,
          payload.state,
          payload.postCode,
          payload.country,
          payload.phone
        )
      );
    },
    async approveValidationAddress({ commit }, payload) {
      commit(
        'SET_APPROVE_ADDRESS',
        await idiviaService.approveValidationAddress(
          payload.id,
          payload.address,
          payload.firstName,
          payload.lastName,
          payload.city,
          payload.postalCode,
          payload.state,
          payload.country,
          payload.validateAddress,
          payload.notes
        )
      );
    },
    async getAllValidationAddress({ commit }) {
      commit('SET_ORDERS_VALIDATE_ADDRESS', await idiviaService.getAllValidationAddress());
    },
    async getOrdersLost({ commit }) {
      commit('SET_ORDERS_LOST', await idiviaService.getOrdersLost());
    },
    async statusUpdate({ commit }, payload) {
      commit('EDIT_STATUS', await idiviaService.statusUpdate(payload.id, payload.status));
    },
    async duplicateOrder({ commit }, payload) {
      commit(
        'DUPLICATE_ORDER',
        await idiviaService.duplicateOrder(payload.id, payload.newInvoice, payload.notes, payload.creditNote)
      );
    },
    async lostOrder({ commit }, payload) {
      commit('LOST_ORDER', await idiviaService.lostOrder(payload.id, payload.status));
    },
    async addEmailEvaluation({ commit }, payload) {
      commit(
        'ADD_EMAIL_EVALUATION',
        await idiviaService.addEmailEvaluation(
          payload.name,
          payload.email,
          payload.dateStart,
          payload.dateEnd,
          payload.emailPortugues,
          payload.emailSpanish,
          payload.emailJapanese
        )
      );
    },
    async internReturn({ commit }, payload) {
      commit('RETURN_INTERN', await idiviaService.internReturn(payload.id, payload.newInvoice));
    },
    async deleteEmail({ commit }, payload) {
      commit('DELETE_EMAIL', await idiviaService.deleteEmail(payload.id));
    },
    async validateReportFilter({ commit }, payload) {
      commit('SET_VALIDITY_REPORTS', await idiviaService.filterValidityReport(payload.month));
    },
    async validityReport({ commit }) {
      commit('SET_VALIDITY_REPORTS', await idiviaService.validityReport());
    },
    async updateEmailMarketing({ commit }, payload) {
      commit(
        'EDIT_EMAIL_MARKETING',
        await idiviaService.updateEmailMarketing(
          payload.id,
          payload.email,
          payload.dateStart,
          payload.dateEnd,
          payload.name,
          payload.emailPortugues,
          payload.emailSpanish,
          payload.emailJapanese
        )
      );
    },
    async getEmailMarketing({ commit }, payload) {
      commit('SET_EMAIL_MARKETING', await idiviaService.getEmailMarketing(payload.id));
    },
    async updateDaysToEvaluation({ commit }, payload) {
      commit('EDIT_DAYS_TO_EVALUATION', idiviaService.updateDaysToEvaluation(payload.id, payload.newDays));
    },
    async updateDaysToDelivered({ commit }, payload) {
      commit('EDIT_DAYS_TO_DELIVERED', idiviaService.updateDaysToDelivered(payload.id, payload.newDays));
    },
    async daysToEvaluation({ commit }) {
      commit('SET_DAYS_TO_EVALUATION', await idiviaService.daysToEvaluation());
    },
    async countDaysToDelivered({ commit }, payload) {
      commit('SET_DAYS_TO_DELIVERED', await idiviaService.countDaysToDelivered(payload.company));
    },
    async returnedProducts({ commit }, payload) {
      commit('RETURNED_PRODUCTS', await idiviaService.returnedProducts(payload.idOrder));
    },
    async allProcessingOrders({ commit }) {
      commit('SET_ALL_ORDERS_PROCESSING', await idiviaService.getOrdersAllProcessing());
    },
    async backOrder({ commit }, payload) {
      commit('BACK_ORDER', await idiviaService.backOrder(payload.id, payload.comment, payload.invoice));
    },
    async productPurchase({ commit }, payload) {
      commit('PURCHASE_PRODUCT', await idiviaService.productPurchase(payload.id));
    },
    async updatePurchase({ commit }, payload) {
      commit(
        'UPDATE_PURCHASE',
        await idiviaService.updatePurchase(
          payload.articles,
          payload.supplier,
          payload.invoiceId,
          payload.paymentMethod,
          payload.totalPay,
          payload.discount,
          payload.id,
          payload.creditNoteValue,
          payload.creditNoteNumber
        )
      );
    },
    async sendEmailTransport({ commit }, payload) {
      commit('SEND_ORDER_EMAIL'), await idiviaService.sendEmailTransport(payload.id);
    },
    async sendEmailTransportS({ commit }, payload) {
      commit('SEND_ORDER_EMAIL'), await idiviaService.sendEmailTransportS(payload.id);
    },
    async getCTTFileToday({ commit }, payload) {
      commit('SET_CTT_FILE', await idiviaService.cttFileToday(payload.shipments, payload.numberInvoice));
    },
    async getPickingByID({ commit }, payload) {
      commit('PICKING_BY_ID', await idiviaService.pickingByID(payload.id));
    },
    async calculatePicking({ commit }) {
      commit('CALCULATE_PICKING', await idiviaService.calculatePicking());
    },
    async setPriority({ commit }, payload) {
      commit('SET_ORDER_PRIORITY', await idiviaService.setPriority(payload.id));
    },
    async setPendent({ commit }, payload) {
      commit('SET_ORDER_PENDENT', await idiviaService.setPendent(payload.id));
    },
    async setPendentCS({ commit }, payload) {
      commit('SET_ORDER_PENDENT', await idiviaService.setPendentCS(payload.id, payload.bool));
    },
    async setPendentEdit({ commit }, payload) {
      commit('SET_ORDER_PENDENT', await idiviaService.setPendentEdit(payload.id));
    },
    /* FUNÇÃO QUE CHAMA A API ONDE OBTEMOS OS PRODUTOS */
    async fetchProducts({ commit }) {
      commit('SET_PRODUCTS', await idiviaService.getProducts());
    },
    async fetchProductID({ commit }, payload) {
      commit('SET_PRODUCTS_ID', await idiviaService.getProductID(payload.id));
    },
    async fetchProductsPurchase({ commit }) {
      commit('SET_PRODUCTS', await idiviaService.getProductsPurchase());
    },
    async fetchProductsEAN({ commit }) {
      commit('SET_PRODUCTS_EAN', await idiviaService.getEAN());
    },
    async getAmostras({ commit }, payload) {
      commit('SET_AMOSTRAS', await idiviaService.getAmostras(payload.order));
    },
    async getCodigoOrder({ commit }, payload) {
      commit('SET_CODIGO_ORDER', await idiviaService.getCodigoOrder(payload.id));
    },
    async getTaxa({ commit }, payload) {
      commit('SET_TAXS', await idiviaService.getTaxs(payload.country));
    },
    async fetchProductsSKU({ commit }, payload) {
      commit('SET_PRODUCTS_SKU', await idiviaService.getProductBySKU(payload.sku));
    },
    async fetchOrders({ commit }) {
      commit('SET_ORDERS', await idiviaService.getOrders());
    },
    async getPreviousOrderInfo({ commit }, payload) {
      commit('SET_PREVIOUS_ORDER_INFO', await idiviaService.getPreviousOrderInfo(payload.id));
    },
    async fetchOrdersFilterJunt({ commit }, payload) {
      commit('SET_ORDERS_FILTER', await idiviaService.getOrdersFilterJunt(payload.email));
    },
    async fetchOrdersFilter({ commit }, payload) {
      commit(
        'SET_ORDERS_FILTER',
        await idiviaService.getOrdersFilter(payload.id, payload.name, payload.email, payload.tracking)
      );
    },
    async putProduct({ commit }, payload) {
      commit(
        'EDIT_PRODUCT',
        await idiviaService.editProduct(
          payload.id,
          payload.ean,
          payload.sku,
          payload.sku_old,
          payload.warehouse,
          payload.finish,
          payload.weight,
          payload.hscode,
          payload.country,
          payload.new,
          payload.validity
        )
      );
    },
    async updateStockStatus({ commit }, payload) {
      commit('EDIT_PRODUCT', await idiviaService.updateStockStatus(payload.id, payload.status));
    },
    async updatePriceOption({ commit }, payload) {
      commit('EDIT_PRODUCT', await idiviaService.updatePriceOption(payload.id, payload.status));
    },
    async fixStock({ commit }, payload) {
      commit('FIX_STOCK', await idiviaService.fixStock(payload.id, payload.qty, payload.reason, payload.comment));
    },
    async fetchOrdersProcessing({ commit }) {
      commit('SET_ORDERS_PROCESSING', await idiviaService.getOrdersProcessing());
    },

    async fetchOrderById({ commit }, payload) {
      commit('SET_ORDER_BY_ID', await idiviaService.getOrderId(payload.id));
    },
    async editProductName({ commit }, payload) {
      commit('EDIT_PRODUCT', await idiviaService.editProductName(payload.id, payload.name));
    },
    async fetchOrderProducts({ commit }) {
      commit('SET_ORDER_PRODUCTS', await idiviaService.getOrderProducts());
    },
    async returnedOrder({ commit }, payload) {
      commit(
        'UPDATE_RETURN',
        await idiviaService.returnedOrder(payload.id, payload.articles, payload.comment, payload.validity)
      );
    },
    async updateStatusOrder({ commit }, payload) {
      commit(
        'UPDATE_ORDER_STATUS',
        await idiviaService.updateStatusOrder(
          payload.id,
          payload.orderData,
          payload.status,
          payload.notes,
          payload.creditNote
        )
      );
    },
    async addOrderProducts({ commit }, payload) {
      commit('ADD_ORDER_PRODUCTS', await idiviaService.addOrderProducts(payload.id, payload.products, payload.picking));
    },

    async updateAddress({ commit }, payload) {
      commit(
        'UPDATE_ADDRESS',
        await idiviaService.updateAddress(
          payload.id,
          payload.firstName,
          payload.lastName,
          payload.address,
          payload.city,
          payload.postalCode,
          payload.state,
          payload.country,
          payload.notes
        )
      );
    },
    async updateContact({ commit }, payload) {
      commit('UPDATE_CONTACT', await idiviaService.updateContact(payload.id, payload.contact, payload.email));
    },
    async updateStatus({ commit }, payload) {
      commit('UPDATE_STATUS', await idiviaService.updateCancelled(payload.id));
    },
    async addNewOrder({ commit }, payload) {
      commit(
        'ADD_ORDER',
        await idiviaService.createNewOrder(
          payload.id,
          payload.articles,
          payload.address1,
          payload.city,
          payload.state,
          payload.postCode,
          payload.country,
          payload.shippingLines,
          payload.phone,
          payload.email,
          payload.firstName,
          payload.lastName,
          payload.status,
          payload.currency,
          payload.total,
          payload.paymentMethods,
          payload.feeLines,
          payload.couponLines,
          payload.shippingCompany,
          payload.othersChoice,
          payload.passTax
        )
      );
    },
    async addManualOrder({ commit }, payload) {
      commit(
        'ADD_MANUAL_ORDER',
        await idiviaService.createNewManualOrder(
          payload.orderProducts,
          payload.firstName,
          payload.secondName,
          payload.email,
          payload.finalAdress,
          payload.city,
          payload.country,
          payload.postCode,
          payload.phone,
          payload.state,
          payload.shippingCompany,
          payload.exchange,
          payload.shippingLines,
          payload.total,
          payload.feeLine,
          payload.site
        )
      );
    },
    async addOrdersProcessing({ commit }) {
      commit('ADD_ORDER_PROCESSING', await idiviaService.getProcessingOrders());
    },
    async putDataProduct({ commit }, payload) {
      commit('EDIT_DATA_PRODUCTS', await idiviaService.updateDataFromDB(payload.id));
    },
    async putOrderProducts({ commit }, payload) {
      commit(
        'EDIT_ORDER_PRODUCTS',
        await idiviaService.updateOrdersProduct(
          payload.id,
          payload.products,
          payload.picking,
          payload.orderId,
          payload.sku,
          payload.ean
        )
      );
    },
    async putOrderArticles({ commit }, payload) {
      commit(
        'EDIT_ORDER_ARTICLES',
        await idiviaService.updateArticlesFromOrder(payload.id, payload.articles, payload.total)
      );
    },
    async searchProduct({ commit }, payload) {
      commit(
        'SET_PRODUCTS_FILTER',
        await idiviaService.searchProducts(payload.sku, payload.name, payload.ean, payload.brand)
      );
    },
    async searchProductsEanSku({ commit }, payload) {
      commit('SET_PRODUCTS_EAN_SKU', await idiviaService.searchProductsEanSku(payload.search));
    },
    async fetchShippingCompany({ commit }) {
      commit('SET_SHIPPING_COMPANY', await idiviaService.getShippingCompany());
    },
    async fetchOrderPicking({ commit }) {
      commit('SET_ORDERS_PICKING', await idiviaService.getOrderPicking());
    },
    async fetchOrderExpedition({ commit }) {
      commit('SET_ORDER_EXPEDITION', await idiviaService.getOrderExpedition());
    },
    async fetchOrderExpeditionByID({ commit }, payload) {
      commit('SET_ORDER_EXPEDITION_BY_ID', await idiviaService.getOrderExpeditionById(payload.id));
    },
    async login({ commit }, payload) {
      commit('LOGIN', await idiviaService.login(payload.email, payload.password));
    },
    async postUser({ commit }, payload) {
      commit(
        'REGISTER',
        await idiviaService.register(payload.email, payload.username, payload.password, payload.confPassword)
      );
    },
    async users({ commit }) {
      commit('SET_ALL_USERS', await idiviaService.getAllUsers());
    },
    async updateAdimType({ commit }, payload) {
      commit('UPDATE_ADIM_TYPE', await idiviaService.updateAdminType(payload.email, payload.bool));
    },
    async updateBlocked({ commit }, payload) {
      commit('UPDATE_BLOCKED', await idiviaService.updateBlocked(payload.email, payload.bool));
    },
    async updatePassword({ commit }, payload) {
      commit(
        'UPDATE_PASSWORD',
        await idiviaService.updatePassword(payload.id, payload.oldPassword, payload.newPassword, payload.newPassword2)
      );
    },
    //Countries
    async fetchCountries({ commit }) {
      commit('SET_COUNTRIES', await idiviaService.getCountry());
    },
    async fetchAllCountries({ commit }) {
      commit('SET_ALL_COUNTRIES', await idiviaService.getAllCountries());
    },
    async updateTrackingCountry({ commit }, payload) {
      commit(
        'DO_NOTHING',
        await idiviaService.updateTrackingCountry(
          payload.country,
          payload.tracking_ini,
          payload.tracking_final,
          payload.tracking_single,
          payload.tax_iva,
          payload.tax_iva_reduced
        )
      );
    },
    /* SHIPPING COMPANIES */
    async fetchExpeditionNacex({ commit }, payload) {
      commit('SET_EXPEDITION_NACEX', await idiviaService.getExpeditionData(payload.expedition));
    },
    async createExpedition({ commit }, payload) {
      commit('CREATE_EXPEDITION', await idiviaService.nacexCreateExpedition(payload.data, payload.orderId));
    },
    async fetchTagNacex({ commit }, payload) {
      commit('SET_TAG_NACEX', await idiviaService.nacexGetTag(payload.expedition));
    },
    async deleteExpeditionNacex({ commit }, payload) {
      commit('DELETE_EXPEDITION_NACEX', await idiviaService.nacexCancelExpedition(payload.expedition));
    },
    async shipmentDHL({ commit }, payload) {
      commit(
        'SET_SHIPMENTS',
        await idiviaService.dhlCreateShipment(
          payload.phone,
          payload.companyName,
          payload.fullName,
          payload.cityName,
          payload.countryName,
          payload.postalCode,
          payload.addressLine1,
          payload.addressLine2,
          payload.countryName,
          payload.weight,
          payload.length,
          payload.width,
          payload.height,
          payload.order,
          payload.description,
          payload.email,
          payload.volumetric
        )
      );
    },
    async labelDHL({ commit }, payload) {
      commit('SET_LABEL_DHL', await idiviaService.dhlLabel(payload.expedition));
    },
    async shipmentDHLExtraComunitary({ commit }, payload) {
      commit(
        'SET_SHIPMENTS_EXTRACOMUNITARY',
        await idiviaService.dhlCreateShipmentExtraComunitary(
          payload.phone,
          payload.companyName,
          payload.fullName,
          payload.cityName,
          payload.countryName,
          payload.postalCode,
          payload.addressLine1,
          payload.addressLine2,
          payload.countryName,
          payload.weight,
          payload.length,
          payload.width,
          payload.height,
          payload.order,
          payload.description,
          payload.value,
          payload.currency,
          payload.pdf,
          payload.lineItems,
          payload.invoice,
          payload.email,
          payload.shipping,
          payload.volumetric
        )
      );
    },
    async fetchTrackingDHL({ commit }, payload) {
      commit('SET_TRACKING_DHL', await idiviaService.dhlTracking(payload.expedition));
    },
    async fetchDHLCodes({ commit }) {
      commit('SET_DHL_CODES', await idiviaService.getDHLCodes());
    },
    async shipmentCTT({ commit }, payload) {
      commit(
        'SET_SHIPMENT_CTT',
        await idiviaService.cttCreateShipment(
          payload.orders,
          payload.postalCode,
          payload.weight,
          payload.description,
          payload.country,
          payload.orderId
        )
      );
    },
    async shipmentCTTExtra({ commit }, payload) {
      commit(
        'SET_SHIPMENT_CTT_EXTRA',
        await idiviaService.cttCreateShipmentExtra(
          payload.orders,
          payload.postalCode,
          payload.weight,
          payload.description,
          payload.contentPiece,
          payload.fullName,
          payload.cityName,
          payload.addressLine1,
          payload.phone,
          payload.country,
          payload.orderId,
          payload.state,
          payload.ctt_azul
        )
      );
    },
    async labelCtt({ commit }, payload) {
      commit('SET_LABEL_CTT', await idiviaService.cttLabel(payload.expedition));
    },
    async shipmentCTTExpresso({ commit }, payload) {
      commit(
        'SET_SHIPMENT_CTTEXPRESSO',
        await idiviaService.cttExpressoShipment(
          payload.orders,
          payload.weight,
          payload.fullName,
          payload.cityName,
          payload.postalCode,
          payload.addressLine,
          payload.phone,
          payload.description,
          payload.orderId,
          payload.length,
          payload.width,
          payload.height
        )
      );
    },
    async shipmentCTTExpresso48({ commit }, payload) {
      commit(
        'SET_SHIPMENT_CTTEXPRESSO_48',
        await idiviaService.cttExpresso48h(
          payload.orders,
          payload.weight,
          payload.fullName,
          payload.cityName,
          payload.postalCode,
          payload.addressLine,
          payload.phone,
          payload.description,
          payload.orderId,
          payload.length,
          payload.width,
          payload.height,
          payload.customsItems,
          payload.totalProducts,
          payload.country
        )
      );
    },
    async shipmentCTTExpressoInternational({ commit }, payload) {
      commit(
        'SET_SHIPMENT_CTTEXPRESSO_INTERNATIONAL',
        await idiviaService.cttExpressoInternationalShipment(
          payload.orders,
          payload.customsItems,
          payload.fullName,
          payload.cityName,
          payload.postalCode,
          payload.addressLine,
          payload.weight,
          payload.length,
          payload.width,
          payload.height,
          payload.phone,
          payload.totalProducts,
          payload.description,
          payload.country,
          payload.orderId
        )
      );
    },
    async labelCTTExpresso({ commit }, payload) {
      commit('SET_LABEL_CTTEXPRESSO', await idiviaService.cttExpressoLabel(payload.expedition));
    },
    async closeShipmentCTTExpresso({ commit }, payload) {
      commit('SET_CTT_EXPRESSO_CLOSE', await idiviaService.cttExpressoClose(payload.shipments));
    },
    async allShipmentsCTTExpresso({ commit }) {
      commit('SET_CTT_EXPRESSO_SHIPMENTS', await idiviaService.cttExpressoShipments());
    },
    async allShipmentsCTT({ commit }) {
      commit('SET_CTT_SHIPMENTS', await idiviaService.cttShipments());
    },
    async allNCloseShipment({ commit }) {
      commit('SET_ALL_CTT_CLOSE', await idiviaService.cttNAllShippingClose());
    },
    async allCloseShipment({ commit }) {
      commit('SET_ALL_CTT_CLOSE', await idiviaService.cttAllShippingClose());
    },
    async filterCttExpressoShipments({ commit }, payload) {
      commit('SET_ALL_CTT_CLOSE', await idiviaService.filterCttExpressoShipments(payload.type));
    },
    async cttValid({ commit }) {
      commit('SET_VALID_CTT', await idiviaService.cttValid());
    },
    async updateCttValid({ commit }) {
      commit('UPDATE_VALID_CTT', await idiviaService.updateCttValid());
    },
    async cttExpressoDeleteShipment({ commit }, payload) {
      commit('DELETE_CTT_EXPRESSO_SHIPMENT', await idiviaService.cttExpressoCancelShipment(payload.id));
    },
    async cttDeleteShipment({ commit }, payload) {
      commit('DELETE_CTT_EXPRESSO_SHIPMENT', await idiviaService.cttCancelShipment(payload.id));
    },
    async putXML({ commit }, payload) {
      commit('EDIT_XML'), await idiviaService.updateXML(payload.id);
    },
    async sendXML({ commit }, payload) {
      commit('SEND_XML'), await idiviaService.sendXML(payload.xml, payload.orderId, payload.exp);
    },
    async getCurrencyFedEx({ commit }, payload) {
      commit('SET_CURRENCY_FEDEX', await idiviaService.getCurrencyFedEx(payload.countryCode));
    },
    async cancelFedexShipment({ commit }, payload) {
      commit('SET_CANCEL_SHIPMENT', await idiviaService.cancelShipment(payload.tracking, payload.id));
    },
    async createShipmentFedEx({ commit }, payload) {
      commit(
        'SET_SHIPMENT_FEDEX',
        await idiviaService.fedExCreateShipment(
          payload.orderId,
          payload.fullName,
          payload.firstName,
          payload.lastName,
          payload.email,
          payload.phoneNumber,
          payload.address1,
          payload.city,
          payload.state,
          payload.postCode,
          payload.country,
          payload.weight,
          payload.commodities,
          payload.pdf
        )
      );
    },
    /* MOLONI */
    async fetchInvoices({ commit }, payload) {
      commit('CREATE_SIMPLIFIED_INVOICE', await idiviaService.createSimplifiedInvoices(payload.order));
    },

    async fetchPDFLink({ commit }, payload) {
      commit('SET_PDF_LINK', await idiviaService.getPDFLink(payload.id));
    },
    async fetchPDFFile({ commit }, payload) {
      commit('SET_PDF_FILE', await idiviaService.getPDFFile(payload.id));
    },
    async fetchInvoiceData({ commit }, payload) {
      commit('SET_INVOICE', await idiviaService.getInvoice(payload.id));
    },
    async addNotes({ commit }, payload) {
      commit('ADD_NOTES', await idiviaService.addNotes(payload.id, payload.notes));
    },

    /* EMAIL */
    async fetchEmail({ commit }, payload) {
      commit(
        'POST_EMAIL',
        await idiviaService.sendEmail(
          payload.order,
          payload.company,
          payload.trackingId,
          payload.resend,
          payload.price_eur
        )
      );
    },

    /* INVOICE SUPPLIERS */
    async fetchInvoiceSuppliers({ commit }, payload) {
      commit(
        'ADD_INVOICE_SUPPLIERS',
        await idiviaService.addInvoicesSuppliers(
          payload.invoiceId,
          payload.products,
          payload.date,
          payload.supplier,
          payload.status,
          payload.total,
          payload.totalWithoutIVA,
          payload.discount,
          payload.extraValue,
          payload.valueInvoice,
          payload.justification,
          payload.withIva
        )
      );
    },
    async updateInvoiceSuppliers({ commit }, payload) {
      commit(
        'UPDATE_INVOICE_SUPPLIERS',
        await idiviaService.updateInvoiceSuppliers(
          payload.invoiceId,
          payload.products,
          payload.date,
          payload.supplier,
          payload.status,
          payload.total,
          payload.id,
          payload.totalWithoutIVA,
          payload.discount,
          payload.extraValue,
          payload.valueInvoice,
          payload.justification,
          payload.withIva,
          payload.creditNote
        )
      );
    },
    async removeReception({ commit }, payload) {
      commit('REMOVE_INVOICE_SUPPLIER', await idiviaService.removeReception(payload.id));
    },
    async searchReception({ commit }, payload) {
      commit(
        'SEARCH_RECEPTION',
        await idiviaService.searchReception(
          payload.invoiceID,
          payload.supplier,
          payload.status,
          payload.start_date,
          payload.final_date,
          payload.noInvoice,
          payload.creditNoteStatus
        )
      );
    },
    async getAllInvoicesSuppliers({ commit }) {
      commit('SET_INVOICE_SUPPLIERS', await idiviaService.getAllInvoiceSuppliers());
    },
    async getInvoicesSuppliersByID({ commit }, payload) {
      commit('SET_INVOICE_SUPPLIERS_ID', await idiviaService.getInvoiceSuppliersByID(payload.id));
    },
    async getAllBrands({ commit }) {
      commit('SET_BRANDS', await idiviaService.getAllBrands());
    },
    async updateBrands({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.updateBrand(payload.id, payload.options, payload.discount));
    },
    async getAllSuppliers({ commit }) {
      commit('SET_SUPPLIERS', await idiviaService.getSuppliers());
    },
    async getAllDataSuppliers({ commit }) {
      commit('SET_SUPPLIERS', await idiviaService.getAllDataSuppliers());
    },
    async addSuppliers({ commit }, payload) {
      commit('ADD_SUPPLIERS', await idiviaService.addSuppliers(payload.name, payload.other_name));
    },
    async addAllDataSuppliers({ commit }, payload) {
      commit(
        'ADD_SUPPLIERS',
        await idiviaService.addAllDataSuppliers(
          payload.name,
          payload.other_name,
          payload.email,
          payload.contact,
          payload.nif,
          payload.account,
          payload.password,
          payload.url
        )
      );
    },
    async updateDataSuppliers({ commit }, payload) {
      commit(
        'UPDATE_SUPPLIERS',
        await idiviaService.updateDataSuppliers(
          payload.id,
          payload.name,
          payload.other_name,
          payload.email,
          payload.contact,
          payload.nif,
          payload.account,
          payload.password,
          payload.url
        )
      );
    },
    async updateDataSuppliersInvoiceType({ commit }, payload) {
      commit('UPDATE_SUPPLIERS', await idiviaService.updateDataSuppliersInvoiceType(payload.id, payload.type));
    },
    /* ####################PURCHASES###################### */
    async addProduct({ commit }, payload) {
      commit(
        'ADD_PRODUCTS',
        await idiviaService.addProduct(
          payload.name,
          payload.price,
          payload.sku,
          payload.ean,
          payload.stock,
          payload.weight,
          payload.validade,
          payload.tax_iva
        )
      );
    },
    async addProductPurchase({ commit }, payload) {
      commit('ADD_PRODUCT_INFO', await idiviaService.getProductExtraInfoPur(payload.sku));
    },
    async createProductPurchase({ commit }, payload) {
      commit('ADD_PRODUCT_INFO', await idiviaService.addProductExtraInfoPur(payload.name, payload.sku, payload.ean));
    },
    async getPurchasesTransactions({ commit }) {
      commit('SET_PURCHASES_TRANSACTIONS', await idiviaService.getTransactionsPurchase());
    },
    async getProductsMissing({ commit }) {
      commit('SET_PURCHASES_PRODUCTS_MISSING', await idiviaService.getMissingProducts());
    },
    async getProductsRequests({ commit }) {
      commit('SET_PURCHASES_PRODUCTS_REQUESTED', await idiviaService.getProductsTransactionsRequest());
    },
    async getFarmaOrders({ commit }, payload) {
      commit('SET_PURCHASES_FARMA_ORDERS', await idiviaService.getFarmaTransactions(payload.supplier));
    },
    async getFarmaOrdersConf({ commit }) {
      commit('SET_PURCHASES_FARMA_ORDERSCONF', await idiviaService.getFarmaTransactionsConf());
    },
    async confirmTransaction({ commit }, payload) {
      commit(
        'ADD_TRANSACTIONS_CONFIRM',
        await idiviaService.confirmTransaction(
          payload.id,
          payload.sku,
          payload.qty_confirmed,
          payload.supplier,
          payload.toLab
        )
      );
    },
    async updateTransaction({ commit }, payload) {
      commit(
        'ADD_TRANSACTIONS_UPDATE',
        await idiviaService.updateTransaction(payload.id, payload.sku, payload.qty_confirmed)
      );
    },
    async cancelTransaction({ commit }, payload) {
      commit('ADD_TRANSACTIONS_UPDATE', await idiviaService.cancelTransaction(payload.id));
    },
    async createInvoiceTrans({ commit }, payload) {
      commit(
        'ADD_TRANSACTIONS_INVOICE',
        await idiviaService.createInvoideTrans(
          payload.id,
          payload.supplier,
          payload.generalDiscount,
          payload.valueExtra,
          payload.paymentMethod
        )
      );
    },
    async createInvoiceTransExtern({ commit }, payload) {
      commit(
        'ADD_TRANSACTIONS_INVOICE_EXTERN',
        await idiviaService.createInvoiceTransExtern(
          payload.id,
          payload.supplier,
          payload.generalDiscount,
          payload.valueExtra,
          payload.paymentMethod,
          payload.addToAccounting
        )
      );
    },
    async addTransactionPurchase({ commit }, payload) {
      commit(
        'ADD_TRANSACTIONS_PURCHASE',
        await idiviaService.addTransactionPurchase(
          payload.name,
          payload.sku,
          payload.ean,
          payload.supplier,
          payload.qty_order,
          payload.price,
          payload.qty_confirmed,
          payload.email,
          payload.farma_email,
          payload.discount,
          payload.id_prod,
          payload.id_supp
        )
      );
    },
    async sendEmailMiss({ commit }, payload) {
      commit('SEND_EMAIL_MISS', await idiviaService.sendEmailMiss(payload.obj));
    },
    async updateProducts({ commit }) {
      commit('UPDATE_PRODUCTS_DB', await idiviaService.updateProducts());
    },
    async getLogs({ commit }, payload) {
      commit('SET_LOGS', await idiviaService.searchLogs(payload.date, payload.user, payload.object_id, payload.type));
    },
    async getDashboard({ commit }) {
      commit('SET_DASHBOARD', await idiviaService.searchDashboard());
    },
    async getProductTrans({ commit }, payload) {
      commit(
        'SET_PRODUCTS_TRANS',
        await idiviaService.getProdTrans(payload.id, payload.start_date, payload.final_date)
      );
    },
    async getProductExtraInfo({ commit }, payload) {
      commit('SET_PRODUCTS_EXTRA_INFO', await idiviaService.getProdExtraInfo(payload.id));
    },
    async updateProductExtraInfo({ commit }, payload) {
      commit(
        'DO_NOTHING',
        await idiviaService.updateProdExtraInfo(payload.id, payload.idivia_manual_price, payload.kiooli_manual_price)
      );
    },
    async getTracking({ commit }, payload) {
      commit('SET_TRACKING', await idiviaService.getTracking(payload.order));
    },
    async setOrderDelivered({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.setDelivered(payload.order_id));
    },
    async getTrackingNotDel({ commit }) {
      commit('SET_TRACKING_NOT_DEL', await idiviaService.getTrackingNotDel());
    },
    async printOrders({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.printOrders(payload.orders));
    },
    //Price Auto
    async getPriceVariables({ commit }) {
      commit('SET_PRICE_VARIABLES', await idiviaService.getPriceVariables());
    },
    async updatePriceVariables({ commit }, payload) {
      commit(
        'DO_NOTHING',
        await idiviaService.updatePriceVariables(
          payload.site,
          payload.margin,
          payload.free_shipping,
          payload.payment_perc,
          payload.kg_price,
          payload.mult_price,
          payload.sum_price
        )
      );
    },
    async getPriceIntervalos({ commit }) {
      commit('SET_PRICE_INTERVALOS', await idiviaService.getPriceIntervalos());
    },
    async addPriceIntervalos({ commit }, payload) {
      commit(
        'DO_NOTHING',
        await idiviaService.addIntervaloPrice(
          payload.price_initial,
          payload.price_final,
          payload.price_mult,
          payload.price_sum,
          payload.price_fix,
          payload.weight_initial,
          payload.weight_final,
          payload.weight_mult,
          payload.weight_sum,
          payload.weight_fix,
          payload.site
        )
      );
    },
    async updatePriceIntervalos({ commit }, payload) {
      commit(
        'DO_NOTHING',
        await idiviaService.updateIntervaloPrice(
          payload.id,
          payload.price_initial,
          payload.price_final,
          payload.price_mult,
          payload.price_sum,
          payload.price_fix,
          payload.weight_initial,
          payload.weight_final,
          payload.weight_mult,
          payload.weight_sum,
          payload.weight_fix
        )
      );
    },
    async deletePriceIntervalos({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.deleteIntervaloPrice(payload.id));
    },
    //Shipping
    async getShippingVariables({ commit }) {
      commit('SET_SHIPPING_VARIABLES', await idiviaService.getShippingVariables());
    },
    async updateShippingVariables({ commit }, payload) {
      commit(
        'DO_NOTHING',
        await idiviaService.updateShippingVariables(
          payload.company,
          payload.fuel,
          payload.inflation,
          payload.insurance,
          payload.back_service,
          payload.back_service_vol
        )
      );
    },
    async calculateShipping({ commit }, payload) {
      commit(
        'SET_SHIPPING_CALCULATED',
        await idiviaService.calculateShipping(
          payload.order_id,
          payload.country,
          payload.weight,
          payload.price,
          payload.height,
          payload.width,
          payload.length1,
          payload.skip
        )
      );
    },
    async cs_shipping_company({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.cs_shipping_company(payload.id, payload.company));
    },
    //Products Purchase Lock
    async product_purchase_lock({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.productPurchaseLock(payload.sku, payload.motivo));
    },
    async product_purchase_unlock({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.productPurchaseUnlock(payload.sku));
    },
    //Sales Report
    async salesReport({ commit }) {
      commit('SET_SALES_REPORT', await idiviaService.salesReport());
    },
    async salesReportPicked({ commit }, payload) {
      commit('SET_SALES_REPORT_PICKED', await idiviaService.salesReportPicked(payload.start_date, payload.final_date));
    },
    async salesReportAnual({ commit }, payload) {
      commit('SET_SALES_REPORT_ANUAL', await idiviaService.salesReportAnual(payload.year));
    },
    async salesReportByCountry({ commit }, payload) {
      commit('SET_SALES_REPORT_COUNTRY', await idiviaService.salesReportByCountry(payload.country));
    },
    async salesReportByCountryDate({ commit }, payload) {
      commit(
        'SET_SALES_REPORT_COUNTRY_DATE',
        await idiviaService.salesReportByCountryDate(payload.country, payload.start_date, payload.final_date)
      );
    },
    //New Orders
    async getOrdersPendent({ commit }) {
      commit('SET_ORDERS_PENDENT', await idiviaService.getOrdersPendent());
    },
    async getOrdersPendentCS({ commit }) {
      commit('SET_ORDERS_PENDENTCS', await idiviaService.getOrdersPendentCS());
    },
    async getOrdersPendentEdit({ commit }) {
      commit('SET_ORDERS_PENDENTEDIT', await idiviaService.getOrdersPendentEdit());
    },
    async getOrdersProcessing({ commit }) {
      commit('SET_ORDERS_PROCESSING_NEW', await idiviaService.getOrdersProcessingNew());
    },
    async getOrdersPicking({ commit }) {
      commit('SET_ORDERS_PICKING_NEW', await idiviaService.getOrdersPicking());
    },
    async getOrdersPrinting({ commit }) {
      commit('SET_ORDERS_PRINTING', await idiviaService.getOrdersPrinting());
    },
    async getOrdersPacking({ commit }) {
      commit('SET_ORDERS_PACKING', await idiviaService.getOrdersPacking());
    },
    async getOrdersReturned({ commit }) {
      commit('SET_ORDERS_RETURNED', await idiviaService.getOrdersReturned());
    },
    async setPrinting({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.setPrinting(payload.id));
    },
    async setPicking({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.setPicking(payload.id));
    },
    async setPacking({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.setPacking(payload.orders));
    },
    async getReasonsDelay({ commit }) {
      commit('SET_REASONS_DELAY', await idiviaService.getReasonsDelay());
    },
    async sendReasonsDelay({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.sendReasonDelayEmail(payload.id, payload.reason));
    },
    /* CURRENCY */
    /* async fetchConvert({
      commit
    }, payload) {
      commit("SET_CONVERT", await idiviaService.convertToEUR(payload.from, payload.amount))
    } */

    //Discounts
    async getDiscounts({ commit }) {
      commit('SET_DISCOUNTS', await idiviaService.getDiscounts());
    },
    async addDiscount({ commit }, payload) {
      commit(
        'DO_NOTHING',
        await idiviaService.createDiscount(
          payload.site,
          payload.brand,
          payload.start_date,
          payload.end_date,
          payload.margin,
          payload.free_shipping,
          payload.payment_perc,
          payload.kg_price,
          payload.limit_discount,
          payload.priority
        )
      );
    },
    async updateDiscount({ commit }, payload) {
      commit(
        'DO_NOTHING',
        await idiviaService.updateDiscount(
          payload.id,
          payload.start_date,
          payload.end_date,
          payload.margin,
          payload.free_shipping,
          payload.payment_perc,
          payload.kg_price,
          payload.limit_discount,
          payload.priority
        )
      );
    },
    async deleteDiscount({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.deleteDiscount(payload.id));
    },
    async getProductDiscount({ commit }, payload) {
      commit('SET_DISCOUNT_PRODUCTS', await idiviaService.getDiscountsProducts(payload.id));
    },
    async setDiscountProducts({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.discountsProducts(payload.id, payload.products));
    },
    //Stock Status
    async getStockStatusVar({ commit }) {
      commit('SET_STOCK_STATUS_VAR', await idiviaService.getStockStatusVar());
    },
    async updateStockStatusVar({ commit }, payload) {
      commit(
        'DO_NOTHING',
        await idiviaService.updateStockStatusVar(payload.type, payload.max, payload.min, payload.price)
      );
    },
    //Stock Status
    async getPriceOptionsVar({ commit }) {
      commit('SET_PRICE_OPTIONS_VAR', await idiviaService.getPriceOptionsVar());
    },
    async updatePriceOptionsVar({ commit }, payload) {
      commit(
        'DO_NOTHING',
        await idiviaService.updatePriceOptionsVar(
          payload.level,
          payload.margin,
          payload.validity,
          payload.last_reception,
          payload.last_sale,
          payload.stock,
          payload.auto_days
        )
      );
    },
    //Stock Variables
    async getStockUpdateVar({ commit }) {
      commit('SET_STOCK_UPDATE_VAR', await idiviaService.getUpdateVariables());
    },
    async updateStockVariables({ commit }, payload) {
      commit(
        'DO_NOTHING',
        await idiviaService.updateUpdateVariables(payload.site, payload.price_perc, payload.stock_perc)
      );
    },
    //Join Products
    async joinProducts({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.joinProducts(payload.sku_original, payload.sku_to_join));
    },
    //Token Tracking
    async updateTicketTracking({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.updateTicketTracking(payload.order_id, payload.ticket_id));
    },
    //Clients Orders Info
    async searchClients({ commit }, payload) {
      commit('SET_SEARCH_CLIENTS', await idiviaService.searchClients(payload.name, payload.email, payload.phone));
    },
    async getClientsInfo({ commit }, payload) {
      commit('SET_CLIENT_INFO', await idiviaService.getClientInfo(payload.id));
    },
    async updateClientsInfo({ commit }, payload) {
      commit(
        'DO_NOTHING',
        await idiviaService.updateClientInfo(
          payload.id,
          payload.first_name,
          payload.last_name,
          payload.email,
          payload.phone,
          payload.notes,
          payload.emails_address,
          payload.emails_tracking,
          payload.scam
        )
      );
    },
    //Addresses
    async addClientAddress({ commit }, payload) {
      commit(
        'DO_NOTHING',
        await idiviaService.addClientsAddress(
          payload.id,
          payload.address,
          payload.address2,
          payload.city,
          payload.state,
          payload.postcode,
          payload.country
        )
      );
    },
    async updateClientAddress({ commit }, payload) {
      commit(
        'DO_NOTHING',
        await idiviaService.updateClientsAddress(
          payload.id,
          payload.address_id,
          payload.address,
          payload.address2,
          payload.city,
          payload.state,
          payload.postcode,
          payload.country
        )
      );
    },
    async deleteClientAddress({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.deleteClientsAddress(payload.id, payload.address_id));
    },
    //Translate
    async translateOrderInfo({ commit }, payload) {
      commit('SET_ALL_TRANSLATED', await idiviaService.translateInfo(payload.address, payload.city, payload.state));
    },
    //Categories
    async getCategories({ commit }) {
      commit('SET_CATEGORIES', await idiviaService.allCategories());
    },
    async addCategory({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.addCategory(payload.name));
    },
    async deleteCategory({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.deleteCategory(payload.id));
    },
    async getCategoriesProducts({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.getCategoriesProducts(payload.id, payload.name));
    },
    async updateCategoriesProducts({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.updateCategoriesProducts(payload.id, payload.file));
    },
    //Products
    async getProductsCategories({ commit }, payload) {
      commit('SET_PRODUCTS_CATEGORIES', await idiviaService.getProductsCategories(payload.id));
    },
    async updateProductsCategories({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.updateProductsCategories(payload.id, payload.categories));
    },
    async updateCategoriesViaSku({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.updateCategoriesViaSku(payload.sku, payload.categories));
    },
    //Feeds By Country
    async getFeedsCountry({ commit }) {
      commit('SET_FEEDS_COUNTRY', await idiviaService.allFeedsByCountry());
    },
    async addFeedsCountry({ commit }, payload) {
      commit(
        'DO_NOTHING',
        await idiviaService.addFeedByCountry(
          payload.name,
          payload.countrys,
          payload.excluded_categories,
          payload.language,
          payload.round,
          payload.only_stock,
          payload.free_shipping,
          payload.vendas,
          payload.date_vendas
        )
      );
    },
    async updateFeedsCountry({ commit }, payload) {
      commit(
        'DO_NOTHING',
        await idiviaService.updateFeedByCountry(
          payload.id,
          payload.name,
          payload.countrys,
          payload.excluded_categories,
          payload.language,
          payload.round,
          payload.only_stock,
          payload.free_shipping,
          payload.vendas,
          payload.date_vendas
        )
      );
    },
    async deleteFeedsCountry({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.deleteFeedByCountry(payload.id));
    },
    async generateFeedCountry({ commit }) {
      commit('DO_NOTHING', await idiviaService.generateFeedByCountry());
    },
    //Coins
    async fetchAllCoins({ commit }) {
      commit('SET_COINS', await idiviaService.getCoins());
    },
    async updateCoins({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.updateCoins(payload.coin, payload.rate));
    },
    //Custom Labels
    async getCustomLabels({ commit }) {
      commit('SET_CUSTOM_LABELS', await idiviaService.getCustomLabels());
    },
    async updateCustomLabels({ commit }, payload) {
      commit(
        'DO_NOTHING',
        await idiviaService.updateCustomLabels(payload.name, payload.min, payload.med, payload.max, payload.weight)
      );
    },
    //Carina
    async getCarinaEmails({ commit }) {
      commit('SET_CARINA_EMAILS', await idiviaService.getCarinaEmails());
    },
    async addCarinaEmails({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.addCarinaEmails(payload.info));
    },
    async updateCarinaEmails({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.updateCarinaEmails(payload.id, payload.ticket_id, payload.next_email));
    },
    async deleteCarinaEmails({ commit }, payload) {
      commit('DO_NOTHING', await idiviaService.deleteCarinaEmails(payload.id));
    },
  },
  getters: {
    /* RETORNA AS VARIÁVEIS GLOBAIS */
    getLoginMessage: (state) => state.login,
    getRegisterMessage: (state) => state.register,
    getUsers: (state) => state.users,
    getProducts: (state) => state.products,
    getProduct_ID: (state) => state.product_id,
    getProduct: (state) => state.product,
    getOrders: (state) => state.orders,
    getOrderProcessing: (state) => state.totalProcessing,
    getOrderId: (state) => state.order,
    getOrderProducts: (state) => state.orderProducts,
    getShippingCompany: (state) => state.shippingCompany,
    getOrderPicking: (state) => state.orderPicking,
    getEspeditionNacex: (state) => state.expeditionNacex,
    getTagNacex: (state) => state.tagNacex,
    getOrderExpedition: (state) => state.allExpedition,
    getOrderExpeditionByID: (state) => state.expedition,
    getDhlExpedition: (state) => state.dhlExpedition,
    getCommunityCountries: (state) => state.countries,
    getAllCountries: (state) => state.allCountries,
    getDhlLabel: (state) => state.dhlLabel,
    getDhlTracking: (state) => state.dhlTracking,
    getcodesDHL: (state) => state.pautalCodes,
    getCTTExpressoExpedition: (state) => state.cttExpressoExpedition,
    getCTTExpressoLabel: (state) => state.cttExpressoLabel,
    getFedExData: (state) => state.fedexData,
    getFedExCurrency: (state) => state.fedexCurrency,
    getLoggedUser: (state) => state.loggedUser,
    getTotalPicking: (state) => state.totalPicking,
    getInvoiceNumber: (state) => state.invoiceNumber,
    getInvoiceDate: (state) => state.invoiceDate,
    getMessage: (state) => state.message,
    getCTTExpedition: (state) => state.cttShipment,
    getCTTLabel: (state) => state.cttLabel,
    getProductsEAN: (state) => state.ean,
    getZPL: (state) => state.zpl,
    getCTTTracking: (state) => state.cttTracking,
    getInvoicesSuppliers: (state) => state.suppliersInvoice,
    getInvoicesSuppliersId: (state) => state.suppliersInvoiceID,
    getSuppliers: (state) => state.allSuppliers,
    getBrands: (state) => state.allBrands,
    getMoloniInvoiceId: (state) => state.simplifiedInvoiceId,
    getPurchaseTransactions: (state) => state.purchasesTransactions,
    getcttOrdersClose: (state) => state.cttOrdersClose,
    getcttDataClose: (state) => state.cttDataClose,

    //Purchases
    getProductExtraInfo: (state) => state.productExtraInfo,
    getMissingProducts: (state) => state.productsMissing,
    getMissingProductsLocked: (state) => state.productsMissingLocked,
    getMissingProductsDate: (state) => state.productsMissingDate,
    getProductTransactionsRequest: (state) => state.productsTransactions,
    getFarmaOrders: (state) => state.farmaOrders,
    getFarmaOrdersConf: (state) => state.farmaOrdersConf,
    getTransactionsId: (state) => state.idTransaction,
    getFilterOrders: (state) => state.ordersFilter,
    getAllCTTClose: (state) => state.allCloseCTT,
    getFilterProducts: (state) => state.productsFilter,
    getProductsSkuEan: (state) => state.productsSkuEan,
    getLogs: (state) => state.logs,
    getDashboard: (state) => state.dashboard,
    getAllCTTExpressoShipments: (state) => state.cttExpressoShipments,
    getAllCTTShipments: (state) => state.cttShipments,
    getProdTrans: (state) => state.prodTransactions,
    getProdExtraInfo: (state) => state.prodExtraInfo,
    getAmostras: (state) => state.amostras,
    getCodigosOrder: (state) => state.codigo_order,
    getTaxs: (state) => state.taxs,
    getTracking: (state) => state.tracking,
    getTrackingNotDel: (state) => state.ordersNotDel,
    getSearchReception: (state) => state.searchInvoiceSuppliers,
    getValidCtt: (state) => state.validCtt,
    getPickingTrans: (state) => state.pickingByID,
    getCTTFileToday: (state) => state.cttFile,
    getProductPurchase: (state) => state.purchaseProducts,
    getReturnedProducts: (state) => state.returnedProducts,
    getDaysToDelivered: (state) => state.countingDays,
    getDaysToEvaluation: (state) => state.daysEvaluation,
    getEmail: (state) => state.email,
    getValidityReport: (state) => state.reportsValidity,

    //Orders
    getPreviousOrderInfo: (state) => state.previousOrderInfo,
    getNewID: (state) => state.newID,

    //New Orders
    getOrdersPendent: (state) => state.ordersPendent,
    getOrdersPendentCS: (state) => state.ordersPendentCS,
    getOrdersPendentEdit: (state) => state.ordersPendentEdit,
    getOrdersProcessing: (state) => state.ordersProcessing,
    getOrdersPicking: (state) => state.ordersPicking,
    getOrdersPrinting: (state) => state.ordersPrinting,
    getOrdersPacking: (state) => state.ordersPacking,
    getOrdersReturned: (state) => state.ordersReturned,
    getAllProcessingOrders: (state) => state.allOrdersProcessing,
    getAllLostedOrders: (state) => state.ordersLosted,
    getAllOrdersValidateAddress: (state) => state.ordersValidateAddress,
    getValidationAddress: (state) => state.validationAddress,
    getReasonsDelay: (state) => state.reasonsDelay,

    //Shipping
    getCalculatedShipping: (state) => state.shippingCalculated,
    getCalculatedShipMessage: (state) => state.shippingMessage,
    getShippingVariables: (state) => state.shippingVariables,

    //Products
    //Price Auto
    getPriceVariables: (state) => state.priceVariables,
    getPriceIntervalos: (state) => state.priceIntervalos,

    //Sales Report
    getSalesReport: (state) => state.salesReport,
    getPickedSalesReport: (state) => state.salesReportPicked,
    getAnualSalesReport: (state) => state.salesReportAnual,

    //Discounts
    getDiscounts: (state) => state.discounts,
    getDiscountProducts: (state) => state.discountProducts,

    //Stock status
    getStockStatus: (state) => state.stockStatusVar,

    //Price Options
    getPriceOptions: (state) => state.priceOptionsVar,

    //Stock Variables
    getStockVariables: (state) => state.stockUpdateVar,

    // Feed
    getAllFeedSettings: (state) => state.allFeedSettings,
    getAllFeedValues: (state) => state.allFeedValues,
    getAllFeedInterval: (state) => state.intervalFeed,
    getAllFeedMargemByFeed: (state) => state.margemByFeed,
    getAllFeedShipping: (state) => state.shippingFeed,
    getAllFeedMargem: (state) => state.margensFeed,
    getNoProductsFeed: (state) => state.noProductFeed,

    getMissingBrands: (state) => state.missingBrands,
    getManualPrices: (state) => state.manualPrices,
    getAerosolReport: (state) => state.aerosolReport,

    getRequestsClient: (state) => state.requestClient,
    getAllRequestsClient: (state) => state.allRequestsClient,
    getFilterRequestsClient: (state) => state.filterClientRequests,
    getlastClientRequest: (state) => state.lastClientRequest,
    getProductClient: (state) => state.productClient,
    getProductsClient: (state) => state.allProductClient,
    getSearchClientAddressRequest: (state) => state.searchClientAddress,
    getCountRequestEmail: (state) => state.countRequestEmail,
    getclientDiscount: (state) => state.clientDiscount,
    getClientOrderShipping: (state) => state.clientOrderShipping,
    getallProductsClientPrice: (state) => state.productsClientPrice,
    getListProductsClientPrice: (state) => state.listProductsClientPrice,
    //Clients Orders Info
    getSearchClients: (state) => state.searchClients,
    getClientsInfo: (state) => state.clientInfo,

    //Translated
    getTranslated: (state) => state.allTranslated,

    //Daniela Platform
    getAllBrandsPurchase: (state) => state.allBrandsPurchase,
    getAllDanielaPurchase: (state) => state.allDanielaPurchase,
    getfarmasPurchaseLab: (state) => state.farmasPurchaseLab,

    //Laboratories
    getAllLaboratories: (state) => state.allLabs,
    //Insurance
    getAllInsurances: (state) => state.allInsurances,
    //IMPORTS
    getErrorSKUS: (state) => state.errorSKUS,
    getErrorUpdate: (state) => state.errorUpdate,
    getErrorLimit: (state) => state.errorLimitDate,
    getAllImportFiles: (state) => state.allImportFiles,
    getImportTemplates: (state) => state.importFile,

    //Categories
    getAllCategories: (state) => state.allCategories,

    //Products
    getProductCategories: (state) => state.product_categories,

    //Feeds By Country
    getAllFeedsCountry: (state) => state.allFeeds,

    //Coins
    getAllCoins: (state) => state.allCoins,
    getcheckIfWasInternReturn: (state) => state.wasInternReturn,

    //Custom Labels
    getAllCustomLabels: (state) => state.customLabels,

    getIdInvoice: (state) => state.id_invoice,
    getCttTrackinsOrders: (state) => state.cttTrackinsOrders,

    //Carina
    getAllCarinaEmails: (state) => state.carinaEmails,
    getDeliveriesNotesCtt: (state) => state.deliveriesNotesCtt,

    //Accounting
    getAccountingAllSuppliersWithDifference: (state) => state.suppliersWithDifference,
    getAccountingBySupplier: (state) => state.accountingSuppliers,
    getAccountingAllReceptions: (state) => state.allReceptionsAccounting,
    getSupplier: (state) => state.supplier,
    getReceptionsToReceive: (state) => state.receptionsToAccounting,
    getAccountingDetails: (state) => state.accountingDetails,
  },
  modules: {},
});
