<template>
  <!-- COMPONENTE DA NAVBAR -->
  <div>
    <b-navbar class="nav" toggleable="lg" type="dark" variant="ligth">
      <b-navbar-brand href="/"><img src="@/assets/idivia_backoffice.png" alt="" /></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <!-- ITENS DA NAVBAR -->
        <b-navbar-nav v-if="role != 'farmas' && role != 'client' && role != 'daniela'">
          <b-nav-item href="/"><router-link class="options" to="/about">Produtos</router-link></b-nav-item>
          <b-nav-item href="/"><router-link class="options" to="/orders">Encomendas</router-link></b-nav-item>
          <b-nav-item v-if="role == 'admin'" href="/"
            ><router-link class="options" to="/purchases/trans">Compras</router-link></b-nav-item
          >
          <b-nav-item href="/"><router-link class="options" to="/tracking">Tracking</router-link></b-nav-item>
          <b-nav-item href="/"><router-link class="options" to="/carina">Carina</router-link></b-nav-item>
          <b-nav-item href="/"><router-link class="options" to="/clients">Clientes</router-link></b-nav-item>
          <b-nav-item v-if="role == 'admin'" href="/"
            ><router-link class="options" to="/client/allRequests">Pedidos</router-link></b-nav-item
          >
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right fixed>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em>{{ loggedUser }}</em>
            </template>
            <b-dropdown-item @click="this.profile">Profile</b-dropdown-item>
            <b-dropdown-item @click="this.logout">Log Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import router from '../router';

export default {
  data: function () {
    return {
      loggedUser: [],
      totalProcessing: '',
      role: '',
    };
  },
  methods: {
    getUserInformation() {
      this.loggedUser = JSON.parse(localStorage.getItem('user')).username;
      this.role = JSON.parse(localStorage.getItem('user')).role;
    },
    async logout() {
      await localStorage.removeItem('token');
      await localStorage.removeItem('user');
      router.push('/login');
    },
    profile() {
      router.push('/profile');
    },
  },
  created() {
    this.getUserInformation();
  },
  computed: {
    ...mapGetters(['getLoggedUser']),
  },
};
</script>
<style scoped>
.nav {
  background-color: rgb(255, 147, 147);
}
.nav .options {
  text-decoration: none;
  color: white;
  font-size: 20px;
}

.nav .options:hover {
  color: rgb(216, 216, 216);
}
.nav .options:focus {
  color: rgb(216, 216, 216);
}
.nav img {
  margin-left: 50px;
  width: 150px;
}
.ml-auto {
  position: static;
  right: 40px;
  font-size: 20px;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #ffffff;
  text-decoration: none;
}
</style>
