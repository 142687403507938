<template>
  <div id="app">
    <Navbar v-if="!['Login'].includes($route.name)" />
    <router-view />
  </div>
</template>
<script>
import Navbar from '@/components/Navbar.vue';
export default {
  components: {
    Navbar,
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

/* width */
/* ::-webkit-scrollbar {
  width: 5px;
  border: 6px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #207e80;
} */
</style>
