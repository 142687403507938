import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Vue2Editor from 'vue2-editor';

/* import moment from 'moment' */

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);
Vue.use(Donut);
Vue.use(DatePicker);
Vue.component('v-select', vSelect);
Vue.use(Vue2Editor);
/* Vue.use(moment) */

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
